import React from 'react';

export function IconSun(props) {
  const iconfill = props.iconfill || 'orangered'
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="42" height="35" viewBox="0 0 42 35">
    <path d="m 16.136067,15.83614 h 0.09665 v 0.09665 h -0.09665 z m -9.6934884,0.263275 a 9.6572458,9.6572463 0 1 0 19.3144874,0 9.6572458,9.6572463 0 1 0 -19.3144874,0 m 8.6915274,0 a 0.96572559,0.96572565 0 1 0 1.931451,0 0.96572559,0.96572565 0 1 0 -1.931451,0" stroke="#000000" stroke-width="3.4" fill={iconfill} />
    </svg>
  )
}

export function IconLeo(props) {
  const iconfill = props.iconfill || 'orangered'
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="42" height="35" viewBox="0 0 42 35">
    <path d="m30.99 31.62c-1.03 0.94-2.01 1.42-2.96 1.42-0.81 0-1.5-0.27-2.06-0.81-0.55-0.54-0.83-1.21-0.83-2.01 0-1.06 0.56-2.77 1.67-5.14l0.85-1.82c0.66-1.4 0.98-2.55 0.98-3.42 0-1.12-0.32-2.01-0.97-2.68-0.65-0.68-1.51-1.02-2.58-1.02-1 0-1.81 0.3-2.43 0.9-0.62 0.61-0.92 1.4-0.92 2.37 0 0.91 0.29 1.96 0.87 3.17l0.35 0.72c0.58 1.19 0.87 2.07 0.87 2.64 0 0.9-0.35 1.69-1.03 2.36-0.68 0.67-1.5 1-2.43 1-0.96 0-1.78-0.33-2.45-1.01-0.68-0.68-1.01-1.5-1.01-2.47 0-0.93 0.33-1.74 1-2.41 0.66-0.68 1.46-1.02 2.37-1.02 0.33 0 0.7 0.06 1.1 0.18-0.64-1.23-0.96-2.31-0.96-3.25 0-1.23 0.44-2.25 1.33-3.06 0.89-0.82 2.01-1.23 3.35-1.23 1.47 0 2.65 0.43 3.54 1.29 0.89 0.87 1.33 2.01 1.33 3.44 0 0.71-0.07 1.33-0.22 1.84-0.14 0.52-0.5 1.37-1.05 2.56l-0.61 1.33c-1.09 2.36-1.64 3.88-1.64 4.58 0 0.51 0.15 0.93 0.46 1.26 0.3 0.33 0.7 0.5 1.17 0.5 0.62 0 1.34-0.35 2.14-1.06zM17.89 25.84c0 0.7 0.24 1.29 0.7 1.76 0.47 0.47 1.04 0.72 1.72 0.72 0.67 0 1.23-0.24 1.71-0.72 0.47-0.48 0.71-1.06 0.71-1.74 0-0.7-0.24-1.28-0.7-1.76-0.47-0.47-1.04-0.72-1.73-0.72-0.67 0-1.23 0.24-1.71 0.72-0.47 0.48-0.7 1.06-0.7 1.74z" fill={iconfill} />
    </svg>
  )
}

export function IconVCRF(props) {
  const vcrfill = props.vcrfill || 'orangered'
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 240 240">
    <circle r="87.016663" cy="114.87881" cx="109.77678" fill="#ffffff" />
<g
  transform="matrix(1.0311954,0,0,1.0311954,-6.518109,-3.44451)">
   <path fill={vcrfill} d="M 97.324553,221.72392 C 58.796516,215.46052 26.848899,190.33222 12.796869,155.23894 6.7478618,140.13227 5.4301641,132.83065 5.3815215,114.14911 5.3312099,94.826564 6.9559484,86.378986 13.766284,70.553823 24.691106,45.167838 47.208124,23.411381 73.090489,13.233421 87.404366,7.6046442 95.005008,6.2596693 112.82455,6.2022625 c 21.40788,-0.068967 32.11366,2.4073554 50,11.5653465 64.29079,32.917552 78.90906,118.358141 29.25572,170.993301 -9.0736,9.6185 -17.84475,16.01501 -30.75572,22.42913 -15.82597,7.86228 -26.48223,10.48771 -44.5,10.96368 -7.975,0.21067 -16.75,0.0173 -19.499997,-0.4298 z m 18.963707,-39.06101 c 2.98645,-1.13545 60.65609,-57.93743 63.58855,-62.63183 2.74008,-4.38645 2.45052,-8.40196 -0.93614,-12.98193 -1.58614,-2.14502 -15.87364,-16.786736 -31.75,-32.537139 C 125.3755,52.869922 117.556,45.711354 115.17814,45.205308 c -4.31688,-0.918702 -9.06681,1.266127 -13.54708,6.231264 -4.437885,4.918154 -5.636739,9.769694 -3.420234,13.841053 0.859801,1.579315 8.910194,9.846482 17.889774,18.371482 l 16.3265,15.5 -41.884251,0.5 -41.884255,0.500003 -2.917021,3.26578 c -2.67079,2.9901 -2.91702,3.8962 -2.91702,10.73422 0,6.83802 0.24623,7.74412 2.91702,10.73422 l 2.917021,3.26578 41.889893,0.5 41.889893,0.5 -16.8057,16 c -18.220772,17.34724 -20.319864,20.43613 -17.883959,26.31693 1.438487,3.47281 6.397749,8.45097 10.575829,10.61611 3.5261,1.82727 4.4986,1.89819 7.96371,0.58076 z"/>
</g>
 </svg>
)
}

export function IconVCRB(props) {
  const vcrfill = props.vcrfill || 'orangered'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 240 240">
       <circle  transform="scale(-1,1)"
       r="87.016663" cy="114.87881" cx="-110.45975"
       fill="#ffffff" />
    <g transform="matrix(-1.0311954,0,0, 1.0311954,226.75464,-3.44451)">
         <path fill={vcrfill}
         d="M 97.324553,221.72392 C 58.796516,215.46052 26.848899,190.33222 12.796869,155.23894 6.7478618,140.13227 5.4301641,132.83065 5.3815215,114.14911 5.3312099,94.826564 6.9559484,86.378986 13.766284,70.553823 24.691106,45.167838 47.208124,23.411381 73.090489,13.233421 87.404366,7.6046442 95.005008,6.2596693 112.82455,6.2022625 c 21.40788,-0.068967 32.11366,2.4073554 50,11.5653465 64.29079,32.917552 78.90906,118.358141 29.25572,170.993301 -9.0736,9.6185 -17.84475,16.01501 -30.75572,22.42913 -15.82597,7.86228 -26.48223,10.48771 -44.5,10.96368 -7.975,0.21067 -16.75,0.0173 -19.499997,-0.4298 z m 18.963707,-39.06101 c 2.98645,-1.13545 60.65609,-57.93743 63.58855,-62.63183 2.74008,-4.38645 2.45052,-8.40196 -0.93614,-12.98193 -1.58614,-2.14502 -15.87364,-16.786736 -31.75,-32.537139 C 125.3755,52.869922 117.556,45.711354 115.17814,45.205308 c -4.31688,-0.918702 -9.06681,1.266127 -13.54708,6.231264 -4.437885,4.918154 -5.636739,9.769694 -3.420234,13.841053 0.859801,1.579315 8.910194,9.846482 17.889774,18.371482 l 16.3265,15.5 -41.884251,0.5 -41.884255,0.500003 -2.917021,3.26578 c -2.67079,2.9901 -2.91702,3.8962 -2.91702,10.73422 0,6.83802 0.24623,7.74412 2.91702,10.73422 l 2.917021,3.26578 41.889893,0.5 41.889893,0.5 -16.8057,16 c -18.220772,17.34724 -20.319864,20.43613 -17.883959,26.31693 1.438487,3.47281 6.397749,8.45097 10.575829,10.61611 3.5261,1.82727 4.4986,1.89819 7.96371,0.58076 z"
         id="path5729" />
    </g>
    </svg>
  )
}

export function IconVCRBMax(props) {
  const vcrfill = props.vcrfill || 'orangered'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 44 44">
      <g transform="matrix(-1.201645,0,0,1,
           32.032476,-4.2460559)">
        <rect fill="#ffffff"
           y="6.9854851" x="3.9551299"
           height="18.295967" width="22.128351"/>
         <path fill={vcrfill} d="M 4.9204608,28.904197 C 4.4830024,28.641176 4.1655258,28.347183 3.8725882,27.933835 3.4853608,27.38744 3.2429851,26.802897 3.0966153,26.0624 c -0.07505,-0.379755 -0.07642,-0.413128 -0.1027459,-2.530486 -0.03447,-2.769998 -0.03454,-10.062495 -1.5e-4,-12.873931 0.02648,-2.1646904 0.02764,-2.1937954 0.1028759,-2.5742754 0.1459998,-0.738374 0.3862435,-1.318007 0.7764179,-1.873278 0.3478796,-0.495079 0.6363112,-0.757937 1.0841336,-0.988005 0.1916387,-0.09845 0.2867366,-0.114973 0.8528958,-0.148155 0.3504686,-0.02054 3.3579496,-0.05011 6.6833014,-0.06571 6.297437,-0.02955 11.742046,0.01998 11.984271,0.109017 0.923703,0.339529 1.722015,1.446556 2.034286,2.820961 0.125868,0.553947 0.145375,1.751805 0.145286,8.9155754 -9e-5,7.330412 -0.0226,8.744849 -0.148486,9.334979 -0.144855,0.679036 -0.334831,1.135233 -0.690502,1.658173 -0.352771,0.518674 -0.679508,0.818916 -1.235292,1.135114 L 24.349782,29.115 14.805757,29.1122 5.2617404,29.1094 Z m 10.1744662,-7.97024 c 3.412251,-3.36172 3.87376,-3.822295 3.874064,-3.866226 1.22e-4,-0.01497 -0.229871,-0.258825 -0.511059,-0.541902 -0.851317,-0.857036 -6.544549,-6.462891 -6.589523,-6.488389 -0.03604,-0.02042 -0.04193,0.967934 -0.04193,7.030929 0,3.880083 0.0071,7.054696 0.01571,7.054696 0.0086,0 1.472367,-1.435099 3.252738,-3.189108 z" />
        <rect stroke="#ffffff"
           transform="scale(-1,1)"
           y="11.622134"
           x="-18.796167"
           height="11.695264"
           width="0.10402375"
           strokeWidth="2.40847" />
      </g>
  </svg>
  )
}

export function IconVCRFMax(props) {
  const vcrfill = props.vcrfill || 'orangered'
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 44 44">
      <g transform="matrix(1.201645,0,0,
      1,-3.5664485,-4.2460559)">
   <rect y="6.9854851" x="3.9551299"
      height="18.295967" width="22.128351"
      fill="#ffffff" />
    <path fill={vcrfill} d="M 4.9204608,28.904197 C 4.4830024,28.641176 4.1655258,28.347183 3.8725882,27.933835 3.4853608,27.38744 3.2429851,26.802897 3.0966153,26.0624 c -0.07505,-0.379755 -0.07642,-0.413128 -0.1027459,-2.530486 -0.03447,-2.769998 -0.03454,-10.062495 -1.5e-4,-12.873931 0.02648,-2.1646904 0.02764,-2.1937954 0.1028759,-2.5742754 0.1459998,-0.738374 0.3862435,-1.318007 0.7764179,-1.873278 0.3478796,-0.495079 0.6363112,-0.757937 1.0841336,-0.988005 0.1916387,-0.09845 0.2867366,-0.114973 0.8528958,-0.148155 0.3504686,-0.02054 3.3579496,-0.05011 6.6833014,-0.06571 6.297437,-0.02955 11.742046,0.01998 11.984271,0.109017 0.923703,0.339529 1.722015,1.446556 2.034286,2.820961 0.125868,0.553947 0.145375,1.751805 0.145286,8.9155754 -9e-5,7.330412 -0.0226,8.744849 -0.148486,9.334979 -0.144855,0.679036 -0.334831,1.135233 -0.690502,1.658173 -0.352771,0.518674 -0.679508,0.818916 -1.235292,1.135114 L 24.349782,29.115 14.805757,29.1122 5.2617404,29.1094 Z m 10.1744662,-7.97024 c 3.412251,-3.36172 3.87376,-3.822295 3.874064,-3.866226 1.22e-4,-0.01497 -0.229871,-0.258825 -0.511059,-0.541902 -0.851317,-0.857036 -6.544549,-6.462891 -6.589523,-6.488389 -0.03604,-0.02042 -0.04193,0.967934 -0.04193,7.030929 0,3.880083 0.0071,7.054696 0.01571,7.054696 0.0086,0 1.472367,-1.435099 3.252738,-3.189108 z" />
   <rect
      stroke="#ffffff"
      transform="scale(-1,1)"
      y="11.622134"
      x="-18.796167"
      height="11.695264"
      width="0.10402375"
      strokeWidth="2.40847" />
    </g>
  </svg>
  )
}

