import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ReadOnlyRow = ({ contact, handleEditClick, handleDeleteClick }) => {
  //console.log("Contact:",contact);
  return (
      <tr>
        <td>{contact.d}</td>
        <td>{contact.e}</td>
        <td style={{width: "100%", height: "100%"}}>
          <a onClick={(evt) => handleEditClick(evt, contact)}><FaIcons.FaPencilAlt/></a>&nbsp;
          <a onClick={(evt) => handleDeleteClick(evt, contact)}><AiIcons.AiFillDelete/></a>
        </td>
      </tr>
  );
};

export default ReadOnlyRow;
