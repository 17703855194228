import React, { useState, useEffect} from "react";
//import { useNavigate} from "react-router-dom";
//import { useLocation } from "react-router-dom";
//
import './Home.css';

const imgs = [
  {  image: require('../img/Phase1.jpg'), iphase: 'Phase 1', igid : 'xitem8', vid: 'Phase1.mp4'},
  {  image: require('../img/Phase2.jpg'), iphase: 'Phase 2',  igid : 'xitem9', vid: 'Phase2.mp4' },
  {  image: require('../img/Phase3.jpg'), iphase: 'Phase 3',  igid : 'xitem10', vid: 'Phase3.mp4' },
  {  image: require('../img/Phase4.jpg'), iphase: 'Phase 4',  igid : 'xitem11', vid: 'Phase4.mp4' },
  {  image: require('../img/Phase5.jpg'), iphase: 'Phase 5', igid : 'xitem8', vid: 'Phase5.mp4' },
  {  image: require('../img/Phase6.jpg'), iphase: 'Phase 6',  igid : 'xitem9', vid: 'Phase6.mp4' },
  {  image: require('../img/Phase7.jpg'), iphase: 'Phase 7',  igid : 'xitem10', vid: 'Phase7.mp4' },
  {  image: require('../img/Phase8.jpg'), iphase: 'Phase 8',  igid : 'xitem11', vid: 'Phase8.mp4' },
  {  image: require('../img/Phase9.jpg'), iphase: 'Phase 9',  igid : 'xitem8', vid: 'Phase9.mp4' },
  {  image: require('../img/Phase10.jpg'), iphase: 'Phase 10',  igid : 'xitem9', vid: 'Phase10.mp4' },
  {  image: require('../img/Phase11.jpg'), iphase: 'Phase 11',  igid : 'xitem10', vid: 'Phase11.mp4' },
  {  image: require('../img/Phase12.jpg'), iphase: 'Phase 12',  igid : 'xitem11', vid: 'Phase12.mp4' },
  {  image: require('../img/Phase13.jpg'), iphase: 'Phase 13',  igid : 'xitem8', vid: 'Phase13.mp4' },
  {  image: require('../img/Phase14.jpg'), iphase: 'Phase 14',  igid : 'xitem9', vid: 'Phase14.mp4' },
  {  image: require('../img/Phase15.jpg'), iphase: 'Phase 15',  igid : 'xitem10', vid: 'Phase15.mp4' },
  {  image: require('../img/Phase16.jpg'), iphase: 'Phase 16',  igid : 'xitem11', vid: 'Phase16.mp4' },
  {  image: require('../img/Phase17.jpg'), iphase: 'Phase 17',  igid : 'xitem8', vid: 'Phase17.mp4' },
  {  image: require('../img/Phase18.jpg'), iphase: 'Phase 18',  igid : 'xitem9', vid: 'Phase18.mp4' },
  {  image: require('../img/Phase19.jpg'), iphase: 'Phase 19',  igid : 'xitem10', vid: 'Phase19.mp4' },
  {  image: require('../img/Phase20.jpg'), iphase: 'Phase 20',  igid : 'xitem11', vid: 'Phase20.mp4' },
  {  image: require('../img/Phase21.jpg'), iphase: 'Phase 21',  igid : 'xitem8', vid: 'Phase21.mp4' },
  {  image: require('../img/Phase22.jpg'), iphase: 'Phase 22',  igid : 'xitem9', vid: 'Phase22.mp4' },
  {  image: require('../img/Phase23.jpg'), iphase: 'Phase 23',  igid : 'xitem10', vid: 'Phase23.mp4' },
  {  image: require('../img/Phase24.jpg'), iphase: 'Phase 24',  igid : 'xitem11', vid: 'Phase24.mp4' },
  {  image: require('../img/Phase25.jpg'), iphase: 'Phase 25',  igid : 'xitem8', vid: 'Phase25.mp4' },
  {  image: require('../img/Phase26.jpg'), iphase: 'Phase 26',  igid : 'xitem9', vid: 'Phase26.mp4' },
  {  image: require('../img/Phase27.jpg'), iphase: 'Phase 27',  igid : 'xitem10', vid: 'Phase27.mp4' },
  {  image: require('../img/Phase28.jpg'), iphase: 'Phase 28',  igid : 'xitem11', vid: 'Phase28.mp4' },
];

export default function LunarPhases(props) {
const [, updateComponent] = React.useState();
const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
//  let { state } = useLocation();
//  const navigate = useNavigate();
//  const [mph, setMph] = useState("");
//  const [isOpen, setIsOpen] = useState(false);
/*
  useEffect(() => {
     state['lunar_phase'] = '';
  }, []);
*/

//  let mph = '';
/*
  useEffect(() => {
  if ( state && state['lunar_phase'] && !isNaN(parseFloat(state['lunar_phase']))) {
	     console.log('JJH0 LP ', state['lunar_phase'], 'MPH ',mph);	
     setMph(state['lunar_phase']);
     state['lunar_phase'] = '';
     console.log('JJH3 LP ', state['lunar_phase'], 'MPH ',mph);	
     forceUpdateComponent();
  } else {
     setMph('');
     forceUpdateComponent();
  }
  }, [state]);

  const submitModal = (e) => {
      e.preventDefault();
      navigate('/lphaselookup');
  }

 const goBack = (e) => {
      e.preventDefault();
      state['lunar_phase'] = '';
      navigate('/lphases');
  }
*/

return (
  <>  
    <h1 style={{textAlign: 'center'}}>The 28 Lunar Phases</h1>
	  <br/>
    <div className="xgrid-container2"> 
      {imgs.map((e, index) => (
      <div className="gallery-container w-1 h-1 {e.igid}">
        <div className="gallery-item">
          <a href={e.vid}>
            <div className="image">
              <img src={e.image} alt="people"/>
              <div className="toverlay2">
                <div className="toverlay-text2">
                  <h2 style={{textAlign: 'center'}}>{e.iphase}</h2>
                </div>
              </div>
            </div>
            <div className="text">{e.iphase}</div>
	  </a>
        </div>
      </div>
     ))}

    </div>
  </>
  )
};	
/*
    <div className='btnctr'>
        <button className="pred-btn" onClick={submitModal}>Lunar Phase Lookup</button>&emsp;{mph}
    </div>

      {imgs.map((eY, index) => (
      <div className="gallery-container w-1 h-1 {e.igid}">
        <div className="gallery-item">
          <a href={e.vid}>
            <div className="image">
              <img src={e.image} alt="people"/>
              <div className="toverlay2">
                <div className="toverlay-text2">
                  <h2 style={{textAlign: 'center'}}>{e.iphase}</h2>
                </div>
              </div>
            </div>
            <div className="text">{e.iphase}</div>
	  </a>
        </div>
      </div>
     ))}
 
*/
