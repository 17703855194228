import React from 'react'
const imgs = [
  {  iphase: 'Video Tutorial', igid : 'xitem8', vid: 'sgaHowTo.mp4'},
];

function HowTo() {
  return (
    <>
  <div style={{"textAlign": "center"}}>
    <h1>How To Use SpiritGuideAstrology</h1>
     <div className="xgrid-container2"> 
        {imgs.map((e, index) => (
          <div className="gallery-container w-1 h-1 {e.igid}">
            <div className="gallery-item">
              <a href={e.vid}>
                <div className="image">
		 <img src="images/JackHopkinsSGA.jpg"
		    className="img_ctr" alt="JackHopkins"/>
                    <div className="toverlay2">
                      <div className="toverlay-text2">
                        <h2 style={{textAlign: 'center'}}>{e.iphase}</h2>
                      </div>
                    </div>
                </div>
              <div className="text">{e.iphase}</div>
	  </a>
        </div>
      </div>
     ))}
     </div>
    <h1>Guest Access</h1>
  </div>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
  <p>Everyone has read-only Guest Access to the public charts we have included on this site.</p>
  <p>To look at charts and get predictions, select the Menu Icon at the Upper left hand corner and pick Charts.</p>
  <p>Start typing in the last name of any chart you wish to see. A list will popup below. Just select the one you want.</p>
  <p>You can also click on aspects, interps or timeline below the chart to get more details and interpretations.</p>
  <p>If you get stuck, you can always click on SpiritGuideAstrology in the title bar to start over.</p>
  <p>Colors - Black:Direct &ensp; Red:Retrograde &ensp; Purple:Out Of Bounds &ensp; Green: Out of Bounds and Retrograde</p>
  <p>Some charts contain events. If you see a dropdown to the right of the Chart name, you can select from a set of events. Use the Arrow buttons to move back and forth thru the timeline.</p>
    </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>Sun and Moon</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
    <p>To the Right of the chart, you will see the type of chart being displayed. Below that are information on the Sun and Moon. The 60th Harmonic sign and 360th Harmonic sign are shown for the Sun. The Lunar Phase for the Moon is also shown as well as its 4th Harmonic sign in the 28 Phase Lunar Cycle. For more information on these placements, visit the Jack Hopkins Author page on Amazon Books. </p>
  </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>How to I Find my Lunar/Solar Phase?</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
    <p>Both Lunar Phase and the SubPhase of the Sun can be found by selecting Sun & Moon off the Main Menu. This will provide a textal description of this information including Lunar Phase Number. You may also use the Lunar Phase Number to see a Video of your Lunar Phase by picking Lunar Phases off the Main Menu and selecting the corresponding Lunar Phase. For more details on both Lunar and Solar Phases, select My Books on the Home page.</p>
  </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>Entering your own Charts</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
  <p>If you want to manage your own charts, you will need to signup and login. It's free and you only need to signin and login once. Any charts you enter from then on will be saved.</p>
  <p>The system remembers your login, provided you have not removed your brower's cache or are using another browser. You can always login again, if you don't see your personal charts.</p>
  <p>Select the menu Icon (upper left) and signin using an email and password of your choice.</p>
  <p>Once signed in, you will be taken to the login page. Login, and the New Chart option will appear when you select the menu.</p>
  <p>Once in the New Chart page, just fill out the birth chart data. After you have entered the date/time and city/state, longitude and latitude will be automatically set for you. Submit your new chart then choose Charts from the menu to pull it up.</p>
  <p>No one can see your charts except you. Also, clicking on aspects or interps below the chart will pull up chart details and interpretations.</p>
  </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>Getting Predictions</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
    <p>If you want to run predictions against any chart, start by clicking the GEAR icon to the right of the chart name.</p>
    <p>You will be presented with a variety of choices: Transits, Lunations (New/Full Moons), 100 Days, Solar Arcs, Secondary and Tertiary progressions. Picking S2 Moon allows you to step through the secondary progressed Moon as it changes phases. Pick one and select a date range. The progressed planets will show up around the chart. You can also opt to proceed one or more days at a time.</p>
    <p>Tran2Tran will track transiting planets to transiting planets, ignoring natal planets altogether. This is useful for current events.</p>
    <p>Arrow buttons - Use the Arrow buttons to advance or return to the last predicted event. You can get more information concerning the event from the interps tab.</p>
    <p>Timeline - Many prediction options will include a timeline. Select timeline to get an graphical picture across your date range.</p>
  </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>Events Timeline</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
    <p>Some charts have an event timeline attached to them. By selecting Timelines off the Main Menu and entering in a last name, a timeline is displayed, based on secondary progressions. The colors correspond to the four classical elements of fire, earth, air and water and are based on the 60th Harmonic of the progressed Sun. Not all charts have a timeline. You may want to type in the first letter of the last name to see which charts have this feature. You can aso step though each timeline event for these charts in the Chart Library using any prediction option from the gold gear.</p>
  </div>
  <br/>
  <h1 style={{"textAlign": "center"}}>What are those P3s all about? (Advanced)</h1>
  <div style={{"fontSize": "18px", "margin": "10px"}}>
    <p>P3 is shorthand for Tertiary progressions. The P3 options for Mercury, Venus and Mars return lifetime events using Tertiary progressions. </p>
    <p>Pay close attention to their retrograde periods.</p>
  </div>
  </>
  );
}

export default HowTo
