import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { CTX } from "../components/Store";
import { valDt, valDt2 } from './util';
import { v4 as uuidv4 } from 'uuid';
import './LPhaseLookup.css';
import aiLPhase from './aiLPhase';

export default function LPhaseLookup() {
  const navigate = useNavigate();
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  const NOT_FOUND = -999;

  const bdate = React.createRef(null);
  const btime = React.createRef(null);
  const [city, setCity] = useState("");
  const [stctry, setStctry] = useState("");
  const [lmt, setLmt] = useState("");
  const [jcal, setJcal] = useState("");
  const [blong, setBlong] = useState("");
  const [blat, setBlat] = useState("");
  const [gmtoff, setGmtoff] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const req = "mpinit";
    sendReq({ msg: req });

    return() => {
    };
    forceUpdateComponent();
  }, []);

  useEffect(() => {
    
    if( Object.keys(st.lltzone).length === 0 && st.lltzone.constructor === Object) {
    } else {
      let lng = st.lltzone.lng;
      let lat = st.lltzone.lat;
      let gmt_off = fmtTz(st.lltzone.tz);
      let msg = '';
      if (lng === NOT_FOUND) {
          lng = 0.0;
          lat = 0.0;
          gmt_off = 0.0;
          msg = 'Location not found';
          st.lltzone = {};
        } else {
          if (gmt_off === NOT_FOUND) {
            msg = 'GMT Offset not found. LMT?';
          }
        }
        setBlong(lng);
        setBlat(lat);
        setGmtoff(gmt_off);
        setMessage(msg);
        st.lltzone = {};
      }
    }, [st.lltzone]);

  useEffect(() => {
    if (st.n_moon_phase) { 
      let natal28 = st.n_moon_phase.substring(1);
      let prog28 = st.p_moon_phase.substring(1);
	  console.log('Natal MP',natal28);
          navigate('/lphases',{ state: {lunar_phase: natal28}});
    } else {
      setMessage('');
      }
    }, [st.n_moon_phase]);

  const fmtDttm = (bdt, btm) => {
    let dt = valDt2(bdt);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    let tmhr = parseInt(tm[0]);
    let ampm = 'AM';
    if (tmhr > 12) {
      tmhr -= 12;
      ampm = 'PM';
    }
    return(`${dt} ${tmhr}:${tm[1]}:00 ${ampm}`);
  }

  const fmtDttm2 = (bdt, btm) => {
    let dt = valDt2(bdt);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    return(`${dt} ${tm[0]}:${tm[1]}:00`);
  }

  const fmtDttm3 = (bdt, btm) => {
    let dt = valDt(bdt);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    let tmhr = parseInt(tm[0]);
    let ampm = 'AM';
    if (tmhr > 12) {
      tmhr -= 12;
      ampm = 'PM';
    }
    return(`${dt} ${tmhr}:${tm[1]}:00 ${ampm}`);
  }
  const titleCase = (str) => {
    //console.log('titleCase1', str);
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  const fmtTz = (tz) => {
    //console.log('fmtTz', tz);
    let tz2 = tz.toString();
    if (tz2.indexOf(".") === -1) {
      tz2 = tz2 + '.0';
    }
    return parseFloat(tz2);
  }

  const onBlur = (e) => {
    e.preventDefault();
    //console.log('onBlur', stctry, city, bdate,btime);

    if ((e.target.name === 'stctry' ||
      e.target.name === 'city' ||
      e.target.name === 'bdate' ||
      e.target.name === 'btime' ) &&
      (stctry.length > 0 &&  city.length > 0 && bdate.current.value.length > 0 &&  btime.current.value.length > 0 )) {
      const dttm = fmtDttm3(bdate.current.value, btime.current.value);
      if (dttm === ''){
        setMessage('Invalid Date');
        return;
      }
      const cityst = `${city} ${stctry}`;
      const req = `"{"event":"lltzone-req","data":" { 'cityst': '${cityst}', 'dttm': '${dttm}'}"}"`;
      //console.log('mphase req', req);
      sendReq({ msg: req });
      setMessage('');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    //console.log("onSubmit blat blong gmtoff",blat, blong,gmtoff); 
 
    let gmtos = NOT_FOUND;
    if (gmtoff === NOT_FOUND) {
      if (lmt === true)  {
        gmtos = blong / 15.0;
      }
    } else {
      gmtos = parseFloat(gmtoff);
    }
    if (blat === NOT_FOUND || blong === NOT_FOUND || gmtos === NOT_FOUND) {
      let msg = (gmtos === NOT_FOUND) ? 'GMT Offset not found. LMT?' : 'Location not found';
      setMessage(msg);
      return;
    }

    const dttm = fmtDttm2(bdate.current.value, btime.current.value);
    if (dttm === ''){
      setMessage('Invalid Date');
      return;
    }

    setJcal(false);
    let cal = false;

    let lsReg = localStorage.getItem('sgaRegister');

    const req = `"{"event":"sunmoon-req","data":" { 'dttm': '${dttm}', 'timezone': ${gmtos}, 'longitude': ${blong}, 'latitude': ${blat}, 'city': '${titleCase(city)}', 'state': '${stctry.toUpperCase()}', 'author': '${lsReg}', 'sex': 'M', 'jcal': ${cal} }"}"`;

    sendReq({ msg: req });
    setMessage('');
  }

  const onLMTChange = (e) => {
    setLmt(e.target.checked);
    if ( e.target.checked ===  true &&
      blong !== 0){
        setGmtoff(blong / 15.0 );
      }
  }

  const onJCALChange = (e) => {
    setJcal(e.target.checked);
  }

  const onCityChange = (val) => {
    //console.log('onCityChange val',val,'city',city,'!');
    setCity(val);
  }

  const onStCtryChange = (val) => {
    //console.log('onStCtryChange val',val,'stctry',stctry,'!');
    setStctry(val);
  }

  const onBlongChange = (val) => {
    //console.log('onBlongChange val',val,'blong',blong,'!');
    setBlong(val);
  }

  const onBlatChange = (val) => {
    //console.log('onBlatChange val',val,'blat',blat,'!');
    setBlat(val);
  }

  const onGmtoffChange = (val) => {
   // console.log('onGmtoffChange val',val,'gmtoff',gmtoff,'!');
    setGmtoff(val);
  }

  return (
   <>
   <form id="nchart" onSubmit={onSubmit}>
    <fieldset id="chartInfo">
     <legend>Lunar Phase Lookup</legend>     
     <div className="fRow">
      <label htmlFor="bdate">Date </label>
  	  <input type="text" name="bdate"  ref={bdate}
     	    placeholder="Date" onBlur={onBlur} required/>
 	    <label htmlFor="btime">Time </label>
 	    <input type="time" name="btime"  ref={btime}
    	  placeholder="Time" onBlur={onBlur} required/>
     </div>
     <div className="fRow">
 	    <label htmlFor="city">City </label>
  	  <input type="text" name="city"
 	     placeholder="City" onChange={e => onCityChange(e.target.value)} 
            onBlur={onBlur} required maxLength="80"/>
      
    	<label htmlFor="stctry">State </label>
    	<input type="text" name="stctry"
      		placeholder="State/Country" 
      		onChange={e => onStCtryChange(e.target.value)} 
	      onBlur={onBlur} required maxLength="30" />
	   </div>
        <div className="fRow">
          <label htmlFor="blong">Longitude </label>
    	  <input type="text" name="blong"
        	onChange={e => onBlongChange(e.target.value)}
        	placeholder="Longtitude" value={blong} required/>
        <label htmlFor="blat">Latitude </label>
    	  <input type="text" name="blat"
        	onChange={e => onBlatChange(e.target.value)}
        	placeholder="Latitude" value={blat} required/>
        </div>

        <div className="fRow">
         <label htmlFor="gmtoff">GMT </label>
    	   <input type="text" name="gmtoff"
      		onChange={e => onGmtoffChange(e.target.value)}
              	value={gmtoff} required/>
        <label ></label>
         <input name="lmt" type="checkbox"
	        className='move-left' onChange={onLMTChange}/>
         <label  style={{width: "5%"}} htmlFor="lmt">LMT </label>
    
         <input name="jcal" type="checkbox"
      		className='move-left' onChange={onJCALChange}/>
         <label style={{width: "4%"}} htmlFor="jcal">JCal</label>        
        
    </div>
    </fieldset>
    <div className="fRow fBtn">
      <button className="cli-submit" type="submit">Lookup</button>
    </div><br/>
    <div className="fRow ext-center">
      <span className='emsg'>{message}</span>
    </div>  
 
  </form>
   </> 
  );
}

