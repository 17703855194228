import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import { CTX } from "../components/Store";
import "./TLEdit.css";
import 'react-tabs/style/react-tabs.css';
import data from "./tl-data.json";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";

const TLEdit = () => {
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  const [contacts, setContacts] = useState(data);
  const [lookupname, setLookupname] = useState('');
  const [opts, setOpts] = useState([]);
  const [defval, setDefval] = useState("");
  const [ptype, setPtype] = useState('');
  const [pevtsCur, setPevtsCur] = useState(0);
  const [pEvent, setPEvent] = useState('');
  const [pName, setPName] = useState('');
  const [events, setEvents] = useState([]);

  const [addFormData, setAddFormData] = useState({
      fullName: "",
      address: "",
  });

  const [editEventData, setEditEventData] = useState({
      fullName: "",
      address: "",
  });

  const [editEventId, setEditEventId] = useState(null);

  useEffect(() => {
    if (st.name !== '') {
      setLookupname(st.name);
    }

    if(st.id){
      st.from = '0000-01-01';
      st.to = '0000-01-02';
      if (st.ptype === '' || st.ptype === 'na') {
          st.ptype = "tr";
      }

      let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

      sendReq({ msg: req });
    }
    if (st.tlines && st.tlines.length > 0) {
      let list = st.tlines.map((item, index) => {
        return (
          <option value={item}>{item}</option>
        )
      })
      setOpts(list);
      setDefval("");
    } else {
      setOpts([]);
     }
     forceUpdateComponent();
}, [st.lng, st.tlines]);


	useEffect(() => {
		  setEvents(st.pevts);
	}, [st.pevts]);

	useEffect(() => {
	}, [events]);

useEffect(() => {
	    setPName("JJH");
	  forceUpdateComponent();
}, [st.tlines])

useEffect(() => {
	    st.pevts = [];
}, []);

  const onAddPeriod = (evt) => {
     evt.preventDefault();
     let req = `"{"event":"per_add","data":"{'id': ${st.id}, 'pname':'${pName}'}"}"`;
     sendReq({ msg: req });
     document.getElementById("addper-form").reset();
     setPName("");
  }

  const onAddPerChg = (evt) => {
      evt.preventDefault();
      setPName(evt.target.value);
  };

  const onSearchName = (e) => {
     e.preventDefault();

     if (e.target.value) {
         let lsReg = localStorage.getItem('sgaRegister');
         const req = `"{"event":"lookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}'}"}"`;
         setLookupname(e.target.value);
         sendReq({ msg: req });
     } else {
         setLookupname('');
         st.lookups = [];
     }
     setDefval("");
  };

  const periodChg = e => {
    st.tl_sel = e.target.value;
    st.pidx = 0;

    if (st.ptype === '' || st.ptype === 'na') {
        st.ptype = "tr";
    }
    st.from = '2000-01-01';
    st.to = '2000-01-02';
    setDefval(st.tl_sel);
    setPtype(st.ptype);
    setPevtsCur(0);
    setPEvent("");

    let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

    sendReq({ msg: req });
  }

  const handleAddFormChange = (event) => {
      event.preventDefault();

      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;

      const newFormData = { ...addFormData };
      newFormData[fieldName] = fieldValue;

      setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
      event.preventDefault();

      const fieldName = event.target.getAttribute("name");
      const fieldValue = event.target.value;

      const newFormData = { ...editEventData };
      newFormData[fieldName] = fieldValue;

      setEditEventData(newFormData);
  };

  const handleAddFormSubmit = (evt) => {
      evt.preventDefault();

      const newContact = {
         id: nanoid(),
         d: addFormData.fullName,
         e: addFormData.address,
      };

      const newContacts = [...contacts, newContact];
      setContacts(newContacts);
      let req = `"{"event":"tl_updt","data":"{'id': ${st.id}, 'dt':'${addFormData.fullName}', 'title':'${addFormData.address}','period':'${st.tl_sel}','tl_id': 0,'action':'add' }"}"`;
      sendReq({ msg: req });
      setAddFormData({ fullName: "", address: "" });
      document.getElementById("addevt-form").reset();
  };

  const onLookup = (lookup) => {
      if (lookup.name && lookup.name.length > 0) {
         const req = `"{"event":"chart-req","data":"{'id': ${lookup.id}}"}"`;
         sendReq({ msg: req });
      }
      setLookupname(lookup.name);
      st.lookups = [];
      setDefval("");
  };

  const handleEditFormSubmit = (event) => {
      event.preventDefault();

      const editedContact = {
         id: editEventId,
         fullName: editEventData.fullName,
         address: editEventData.address,
      };

      const newContacts = [...contacts];
      const index = contacts.findIndex((contact) => contact.id === editEventId);
      newContacts[index] = editedContact;
      setContacts(newContacts);
      setEditEventId(null);
  };

   const handleEditClick = (event, contact) => {
     event.preventDefault();
     setEditEventId(contact.a);

     const formValues = {
        fullName: contact.d,
        address: contact.e,
     };

     setEditEventData(formValues);
   };

   const handleCancelClick = () => {
     setEditEventId(null);
   };

  const handleDeleteClick = (evt, contact) => {
     let req = `"{"event":"tl_updt","data":"{'id': ${st.id}, 'dt':'${contact.d}', 'title':'${contact.e}','period':'${st.tl_sel}','tl_id': ${contact.a},'action':'delete' }"}"`;

     const newContacts = [...contacts];
     const index = contacts.findIndex((e) => e.id === contact.id);
     newContacts.splice(index, 1);
     setContacts(newContacts);
     sendReq({ msg: req });
   };

   const onUpdtConfirm = (evt) => {
      evt.preventDefault();
      let req = `"{"event":"tl_updt","data":"{'id': ${st.id}, 'dt':'${editEventData.fullName}', 'title':'${editEventData.address}','period':'${st.tl_sel}','tl_id': ${editEventId},'action':'update' }"}"`;

      setEditEventId(null);
      sendReq({ msg: req });
   };

   return (
        <>
	  <h2 style={{textAlign: "center"}}>Timeline Edit</h2>
          <div className="clihdr">
          <div className="lu01">
            <input
              className="inp01" type="text" value={lookupname}
              onChange={onSearchName} id="inp01" autocomplete="off"
              placeholder="Chart name..." />
           {st.tlines && st.tlines.length > 0 &&
              <select value={defval} onChange={periodChg}>
                <option selected value=""></option>
                {opts}
             </select>
	   }
           </div>
           </div>
           <div className="lu02" id="lookups">
	       <ul className="dd01">
	          {""}
	          {st.lookups.map((lookup, index) => (
	            <li onClick={() => onLookup(lookup)} key={index}>
	              {""}
	              {lookup.name}
	            </li>
	          ))}
	        </ul>
	    </div>
            { st.name && st.name.length > 0 && 
	        <div>
	          <form id="addper-form" className="tlform" onSubmit={onAddPeriod}>
	            <input style={{marginLeft: '10px'}}
	              className="inp01"
	              type="text"
	              name="p"
	              required="required"
	              placeholder="Enter a period..."
	              onChange={onAddPerChg}
	            />
	            <button style={{  fontSize: "12px"}} type="submit">Add</button>
	          </form>
	        </div>
	    }
	    <div className="app-container">
	        <form onSubmit={handleEditFormSubmit}>
	          <table>
	            <thead>
	              <tr>
		        <th width="60">Date</th>
		        <th width="510">Event</th>
		        <th width="50">Actions</th>
	              </tr>
	            </thead>
	            <tbody>
	              {st.pevts.map((item) => (
		      <>
		       {editEventId === item.a ? (
		         <EditableRow
		          editFormData={editEventData}
		          handleEditFormChange={handleEditFormChange}
		          handleCancelClick={handleCancelClick}
	                  handleSubmit={onUpdtConfirm}
		         />
		        ) : (
		         <ReadOnlyRow
		          contact={item}
		          handleEditClick={handleEditClick}
		          handleDeleteClick={handleDeleteClick}
		         />
		       )}
		      </>
		     ))}
	            </tbody>
	          </table>
	        </form>

	        <h2>Add an Event</h2>
	        <form id="addevt-form" onSubmit={handleAddFormSubmit}>
	          <input
	            type="text"
	            name="fullName"
	            required="required"
	            placeholder="Enter a date..."
	            onChange={handleAddFormChange}
	          />
	          <input
	            type="text" autocomplete="off"
	            name="address"
	            required="required"
	            placeholder="Enter an event..."
	            onChange={handleAddFormChange}
	          />
	          <button type="submit">Add</button>
	        </form>
	      </div>
	     </>
	 );
};

export default TLEdit;

