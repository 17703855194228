import React from 'react';
export default function About() {

  return (
    <>
<div style={{"textAlign": "center"}}>
  <h1>Jack Hopkins</h1>
  <h2>hopkinsjj9@gmail.com</h2>
</div>
<div style={{"fontSize": "18px", "margin": "10px"}}>
  <img src="images/JackHopkinsSGA.jpg"
    className="img_ctr" alt="JackHopkins"/>
  <br/>
  <p>Jack Hopkins has been practicing astrology since 1973. His articles have appeared in the Association of Professional Astrologers and ISAR Journals.</p>
  <p>He specializes in personal and relationship charts, forecasting, and using astrology as a tool to provide insights and inspiration for life in a busy world.</p>
  <p>His workshops are dynamic and engaging. They focus on self discovery, creativity, and gaining new perspectives into everyday life.</p>
</div>
</>
  );
}
