import React from "react";

import './Home.css';

const imgs = [
  {  image: require('../img/Houses1-6.jpg'), iphase: 'Houses 1-6', igid : 'xitem8', vid: 'https://youtu.be/h6ntJ5h32Yw'},
  {  image: require('../img/Houses7-12.jpg'), iphase: 'Houses 7-12',  igid : 'xitem9', vid: 'https://youtu.be/gIgI0mVhYTE' },
  {  image: require('../img/fourdirs.jpg'), iphase: 'Four Directions',  igid : 'xitem10', vid: 'https://youtu.be/4F9Cqf7kOmk' },
  {  image: require('../img/karma-feature-3.jpg'), iphase: 'Karma Healing',  igid : 'xitem11', vid: 'https://youtu.be/7b0JSlef2jI' },
  {  image: require('../img/reincarnation.jpg'), iphase: 'Reincarnation', igid : 'xitem8', vid: 'https://youtu.be/mt3qWd-mkI4' },
  {  image: require('../img/sun-1613704_640.jpg'), iphase: 'Sunshine!',  igid : 'xitem9', vid: 'https://youtu.be/s98wrvF9hgU' },
  {  image: require('../img/darkmoon.jpg'), iphase: 'Dark of the Moon',  igid : 'xitem10', vid: 'https://youtu.be/iouWZ8hJ8pg' },
  {  image: require('../img/pluto.jpg'), iphase: 'Pluto',  igid : 'xitem11', vid: 'https://youtu.be/1pV70hpmXWc' },
  {  image: require('../img/springequinox.jpg'), iphase: 'Ostara',  igid : 'xitem8', vid: 'https://youtu.be/F-aEf7VwhoU' },
  {  image: require('../img/Stonehenge_sun.jpg'), iphase: 'Summer Solstice',  igid : 'xitem9', vid: 'https://youtu.be/DaZR8Wb04tE' },
  {  image: require('../img/mabon.jpg'), iphase: 'Fall Equinox',  igid : 'xitem10', vid: 'https://youtu.be/qQMZVxn3Zb0' },
  {  image: require('../img/winter-1828780_640.jpg'), iphase: 'Yule',  igid : 'xitem11', vid: 'https://youtu.be/xa6sMcOgrBM' },
  {  image: require('../img/Chiron-astrology.jpg'), iphase: 'Chiron',  igid : 'xitem8', vid: 'https://youtu.be/wblqXOwGZkQ' },
  {  image: require('../img/astrologymoon.jpg'), iphase: 'The Moon',  igid : 'xitem9', vid: 'https://youtu.be/R5lRGzKUnZ4' },
  {  image: require('../img/Samhain.jpg'), iphase: 'Samhain',  igid : 'xitem10', vid: 'https://youtu.be/ba3-LO_2aqM' },
  {  image: require('../img/moonphases.jpg'), iphase: 'Moon Phases',  igid : 'xitem11', vid: 'https://youtu.be/-Rhqw4-mFVk' },
];
export default function YTVideos() {
	
  return (
  <>  
    <h1 style={{textAlign: 'center'}}>Videos</h1>

    <div className="xgrid-container2"> 
      {imgs.map((e, index) => (
      <div className="gallery-container w-1 h-1 {e.igid}">
        <div className="gallery-item">
          <a href={e.vid}>
            <div className="image">
              <img src={e.image} alt="people"/>
              <div className="toverlay2">
                <div className="toverlay-text2">
                  <h3 className='img-title'>{e.iphase}</h3>
                </div>
              </div>
            </div>
            <div className="text">{e.iphase}</div>
	  </a>
        </div>
      </div>
     ))}
    </div>
  </>
  )
};	
