import React, { useState, useEffect } from "react";
import { CTX } from "../components/Store";
import { valDt } from './util';
import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { v4 as uuidv4 } from 'uuid';
import './NewChart.css';

export default function NewChart() {

  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  const NOT_FOUND = -999;

  const eform = React.createRef(null);
  const fname = React.createRef(null);
  const lname = React.createRef(null);
  const bdate = React.createRef(null);
  const btime = React.createRef(null);
  const blong = React.createRef(null);
  const blat  = React.createRef(null);
  const city  = React.createRef(null);
  const stctry = React.createRef(null);
	
  const [lookupname, setLookupname] = useState('');
  const [lchecked, setLchecked] = useState(false);
  const [jchecked, setJchecked] = useState(false);
  const [genderm, setGenderm] = useState(false);
  const [genderf, setGenderf] = useState(false);
  const [gmtoff, setGmtoff] = useState("");
  const [jcal, setJcal] = useState(false);
  const [pubx, setPubx] = useState(false);
  const [lmt, setLmt] = useState(false);
  const [message, setMessage] = useState("");

  const init = () => {
    st.first_name = '';
    st.last_name = '';
    st.dttm = '';
    st.city = '';
    st.state = '';
    st.longitude = '';
    st.latitude = '';
    st.timezone = '';
    st.sex = '';
    setGenderm(false);
    setGenderf(false);
    setLmt(false);
    setPubx(false);
  }
  
  useEffect(() => {
    init();
    }, []);

  useEffect(() => {
  //console.log('UEFF',st);
    if (st.dttm ) {
      fname.current.value = st.first_name;
      lname.current.value = st.last_name;
      const dttm = st.dttm.split(" ");
      const dt = dttm[0].split("-");
      const dt2 = dt[1].concat( ' ', dt[2], ' ', dt[0]);
      bdate.current.value = dt2;
      btime.current.value = dttm[1];
      city.current.value = st.city;
      stctry.current.value = st.state;
      blong.current.value = st.longitude;
      blat.current.value = st.latitude;
    
      if (st.sex === 'M') {
         setGenderm(true);
         setGenderf(false);
      }
      if (st.sex === 'F') {
         setGenderm(false);
         setGenderf(true);
      }
      setGmtoff(st.timezone);
      setJcal(st.jcal);
      if (st.author === "public@gmail.com") {
          setPubx(true);
      } else {
          setPubx(false);
      }
      setLmt(st.lmt);
      forceUpdateComponent();
    }
    // console.log('pubx',pubx);
    }, [st.first_name]);

  useEffect(() => {
    const NOT_FOUND = -999;
    if(Object.keys(st.lltzone).length === 0 && st.lltzone.constructor === Object) {
    } else {
      let lng = st.lltzone.lng;
      let lat = st.lltzone.lat;
      let gmt_off = fmtTz(st.lltzone.tz);
      let msg = '';
      if (lng === NOT_FOUND) {
          lng = 0.0;
          lat = 0.0;
          gmt_off = 0.0;
          msg = 'Location not found';
          st.lltzone = {};
        } else {
          //console.log('gmtoffset 2 ',  gmtoff);
          if (gmt_off === NOT_FOUND) {
            msg = 'GMT Offset not found. LMT?';
          }
        }
        blong.current.value = lng;
        blat.current.value = lat;
        setGmtoff(gmt_off);
        setMessage(msg);
        st.lltzone = {};
      }
    }, [st.lltzone]);

  const fmtDttm = (bdt, btm) => {
    let dt = valDt(bdt);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    let tmhr = parseInt(tm[0]);
    let ampm = 'AM';
    if (tmhr > 12) {
      tmhr -= 12;
      ampm = 'PM';
    }
    return(`${dt} ${tmhr}:${tm[1]}:00 ${ampm}`);
  }

  const fmtDttm2 = (bdt, btm) => {
    let dt = valDt(bdt);
    if (dt === '') {
      return '';
    }
    const tm = btm.split(':');
    return(`${dt} ${tm[0]}:${tm[1]}:00`);
  }

  const titleCase = (str) => {
    let str2 = str.trim();
    var splitStr = str2.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  const fmtTz = (tz) => {
    //console.log('fmtTz', tz);
    let tz2 = tz.toString();
    if (tz2.indexOf(".") === -1) {
      tz2 = tz2 + '.0';
    }
    //console.log('fmtTz tz2', tz2, parseFloat(tz2));
    return parseFloat(tz2);
  }

  const onBlur = (e) => {
    e.preventDefault();
    //console.log('onBlur1',  e.target.name, e.target.value, stctry.length, city.length);
    //console.log('onBlur2', bdate.current.value.length,  btime.current.value.length);
    if ((e.target.name === 'stctry' ||
      e.target.name === 'city' ||
      e.target.name === 'bdate' ||
      e.target.name === 'btime' ) &&
      (stctry.current.value.length > 0 &&  city.current.value.length > 0 && bdate.current.value.length > 0 &&  btime.current.value.length > 0 )) {
      const dttm = fmtDttm(bdate.current.value, btime.current.value);
      if (dttm === ''){
        setMessage('Invalid Date');
        return;
      }
      const cityst = `${city.current.value} ${stctry.current.value}`;
      const req = `"{"event":"lltzone-req","data":" { 'cityst': '${cityst}', 'dttm': '${dttm}'}"}"`;
      sendReq({ msg: req });
      setMessage('');
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let action = e.nativeEvent['submitter'].id;

    let gmtos = NOT_FOUND;
    if (gmtoff === NOT_FOUND) {
      if (lmt === true)  {
        gmtos = blong / 15.0;
      }
    } else {
      gmtos = parseFloat(gmtoff);
    }

    if (blat === NOT_FOUND || blong === NOT_FOUND || gmtos === NOT_FOUND) {
      let msg = (gmtos === NOT_FOUND) ? 'GMT Offset not found. LMT?' : 'Location not found';
      setMessage(msg);
      return;
    }

    //console.log('gmtos', gmtos);
    const dttm = fmtDttm2(bdate.current.value, btime.current.value);
    if (dttm === ''){
      setMessage('Invalid Date');
      return;
    }

    let d = new Date(bdate.current.value);

    let lsReg = localStorage.getItem('sgaRegister');
    let auth = lsReg;
    if (lsReg === "sga@gmail.com" && pubx) {
        auth = "public@gmail.com";
    }

    let fname1 = titleCase(fname.current.value);
    let lname1 = titleCase(lname.current.value);
    let city1 = titleCase(city.current.value);
    let stctry1 = stctry.current.value.toUpperCase();
    let lng1 = blong.current.value;
    let lat1 = blat.current.value;
    let gender =  genderm ? 'M' : 'F';
    let req = '';

    if (lookupname === '' && action === 'upd') {
      req = `"{"event":"new-chart-req","data":" { 'first_name': '${fname1}', 'last_name': '${lname1}', 'dttm': '${dttm}', 'timezone': ${gmtos},'longitude': ${lng1}, 'latitude': ${lat1}, 'city': '${city1}', 'state': '${stctry1}', 'author': '${auth}', 'sex': '${gender}', 'jcal': ${jcal}, 'lmt': ${lmt} }"}"`;
    }
    if (lookupname !== '' ) {
      req = `"{"event":"updt-chart-req","data":" { 'action': '${action}', 'updt_id': ${st.id}, 'first_name': '${fname1}', 'last_name': '${lname1}', 'dttm': '${dttm}', 'timezone': ${gmtos},'longitude': ${lng1}, 'latitude': ${lat1}, 'city': '${city1}', 'state': '${stctry1}', 'author': '${auth}', 'sex': '${gender}', 'jcal': ${jcal}, 'lmt': ${lmt}}"}"`;
    }
    sendReq({ msg: req });
    blong.current.value = "";
    blat.current.value = "";
    setGmtoff("");
    setJcal(false);
    setPubx(false);
    setLmt(false);
    setGenderf(false);
    setGenderm(false);
    setLookupname("");
    e.target.reset();
    st.lltzone = {};
    setMessage('');
    st.lookups = [];
    init();
    forceUpdateComponent();
  }

  const onLMTChange = (e) => {
    setLmt(e.target.checked);
    if ( e.target.checked ===  true &&
      blong !== 0){
        setGmtoff(blong / 15.0);
      }
  }

  const onJCALChange = (e) => {
    setJcal(e.target.checked);
    //console.log("JCAL jcal", jcal);
  }

  const onPUBXChange = (e) => {
    setPubx(e.target.checked);
    //console.log("JCAL jcal", jcal);
  }
  const onGenderMChange = (val) =>{
    setGenderm(val.target.checked);
    setGenderf(!val.target.checked);
  }

  const onGenderFChange = (val) =>{
    setGenderm(!val.target.checked);
    setGenderf(val.target.checked);
  }

  const onCityChange = (val) => {
    //console.log('onCityChange val',val,'city',city,'!');
    //setCity(val);
  }
  const onStCtryChange = (val) => {
    //console.log('onStCtryChange val',val,'stctry',stctry,'!');
    //setStctry(val);
  }
  const onBlongChange = (val) => {
    //console.log('onBlongChange val',val,'blong',blong,'!');
    //setBlong(val);
  }
  const onBlatChange = (val) => {
    //console.log('onBlatChange val',val,'blat',blat,'!');
    //setBlat(val);
  }
  const onGmtoffChange = (val) => {
    setGmtoff(val);
  }
  const onSearchName = (e) => {
      e.preventDefault();
      if (e.target.value) {
        let lsReg = localStorage.getItem('sgaRegister');
        const req = `"{"event":"lookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}'}"}"`;
        setLookupname(e.target.value);
        sendReq({ msg: req });
      } else {
        setLookupname('');
        st.lookups = [];
      }
  };
  const onLookup = (lookup) => {
    if (lookup.name.length > 0) {
      const req = `"{"event":"existing-chart-req","data":"{'id': ${lookup.id}}"}"`;
      sendReq({ msg: req });
    }
    setLookupname(lookup.name);
    st.lookups = [];
  };

/*
<h3 className='mhdr'>New Chart</h3> 
*/
  return (
    <> 
    {/* client lookup input */}
    <div className="lu01">
      <input autoFocus maxLength = "30" className="inp01"
          type="text" value={lookupname} id = "inp01" autocomplete="off"
          onChange={onSearchName}
          placeholder="Chart name..." />
    </div>
    {/* client lookup candidates */}
    <div className="lu02" id="lookups">
      <ul className="dd01">
        {""}
        {st.lookups.map((lookup, index) => (
          <li onClick={() => onLookup(lookup)} key={uuidv4()}>
            {""}
            {lookup.name}
          </li>
          ))}
      </ul>
    </div>

    <h1 style={{textAlign: 'center'}}>Chart Maintenance</h1>
    <div style={{ margin: '5px', fontWeight: 'bold' }}>
      <Form id='formx' ref={eform} onSubmit={submitForm}>
        <Row className="mb-3">
          <Form.Group as={Col} style={{ gap: "2px" }} controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control name='fname' ref={fname} type="text" maxLength='30' placeholder="First Name" />
          </Form.Group>

          <Form.Group as={Col} controlId="formlastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control name='lname' ref={lname} type="text" maxLength='30' placeholder="Last Name" />
          </Form.Group>
        </Row>
	    
	<Row className="mb-3">
	  <Form.Group as={Col} style={{ gap: "2px" }} controlId="formBirthDate">
	    <Form.Label>Birth Date</Form.Label>
	    <Form.Control type="text" name='bdate' ref={bdate} onBlur={onBlur} placeholder="Birth Date" required />
	  </Form.Group>
	    
	  <Form.Group as={Col} controlId="formBirthTime">
	    <Form.Label>Birth Time</Form.Label>
	    <Form.Control type="time" name='btime' ref={btime} onBlur={onBlur} 
	      placeholder="Birth Time" required />
	  </Form.Group>
	</Row>
	  	  
	<Row className="mb-3">
	  <Form.Group as={Col} style={{gap: "2px"}} controlId="formCity">
	    <Form.Label>City</Form.Label>
	    <Form.Control name='city' ref={city} onChange={e => onCityChange(e.target.value)} 
             onBlur={onBlur} required maxLength="80"/>
	  </Form.Group>

	  <Form.Group as={Col} controlId="formState">
	    <Form.Label>State</Form.Label>
	    <Form.Control name='stctry' placeholder="Birth State" ref={stctry}
              onChange={e => onStCtryChange(e.target.value)} 
	      onBlur={onBlur} required maxLength="30" />
	  </Form.Group>
	</Row>
						      
	<Row className="mb-3">
	  <Form.Group as={Col} style={{gap: "2px"}} controlId="formLongitude">
	    <Form.Label>Longitude</Form.Label>
	    <Form.Control name='blong' onChange={e => onBlongChange(e.target.value)} 
              placeholder="Longtitude" ref={blong} required/>
	  </Form.Group>

	  <Form.Group as={Col} controlId="formLatitude">
	    <Form.Label>Latitude</Form.Label>
	    <Form.Control name='blat' onChange={e => onBlatChange(e.target.value)} 
        	placeholder="Latitude" ref={blat} required/>
	  </Form.Group>
	</Row>
						      
	<Row className="mb-3">
	  <Form.Group as={Col} style={{gap: "2px"}} controlId="formGMToff">
	    <Form.Label>GMT</Form.Label>
	    <Form.Control name='gmtoff' onChange={e => onGmtoffChange(e.target.value)} 
              value={gmtoff} required/>
	  </Form.Group>
	</Row>
						      
	<Row className="mb-3">
          <div className="d-flex justify-content-center">
	  <Form.Group>
	     <Form.Check  type="radio" label="Male" id="male" inline={true}
	        checked={genderm} onChange={onGenderMChange} />&nbsp;
	     <Form.Check  type="radio" label="Female" id="female" inline={true}
	        checked={genderf} onChange={onGenderFChange} />&nbsp;      
	     <Form.Check  type="checkbox" label="LMT" id="lmt" inline={true}
      	        checked={lmt} onChange={onLMTChange}/>&nbsp;
	     <Form.Check  type="checkbox" label="JCAL" id="jcal" inline={true}
      	        checked={jcal} onChange={onJCALChange}/>
             { localStorage.getItem('sgaRegister') === "sga@gmail.com" &&
	     <Form.Check  type="checkbox" label="Public" id="pubx" inline={true}
      	        checked={pubx} onChange={onPUBXChange}/>
	     }
	  </Form.Group>
	  </div>
	</Row>

	<Row>
	<div style={{textAlign: 'center'}}>
	  <Button id='upd' variant="primary" style={{ minWidth: "8rem" }} type="submit">
	    Add/Chg
	  </Button>&nbsp;&nbsp;
	  <Button id='del' variant="primary"  style={{ minWidth: "8rem" }} type="submit">
	    Delete
	  </Button>
        </div>
	</Row>

        <div className="fRow ext-center">
          <span className='emsg'>{message}</span>
        </div>  
      </Form>
    </div>
    </>
  );
}

