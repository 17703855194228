import * as React from "react";

export const SVGComponent = (props) => (
  <svg
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    id="svg11324"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="layer2"
      style={{
        display: "inline",
      }}
    >
      <text
        xmlSpace="preserve"
        style={{
          fontWeight: 900,
          fontSize: "20px",
          lineHeight: 1.25,
          fontFamily: "'Cinzel Decorative'",
          InkscapeFontSpecification: "'Cinzel Decorative Heavy'",
          letterSpacing: 0,
          wordSpacing: 0,
          textOrientation: "upright",
          fill: "#ffffff",
          strokeWidth: 2.85691,
        }}
        x={-1.4318888}
        y={18.691557}
        id="text30057"
        transform="scale(0.94941183,1.0532837)"
      >
        <tspan
          id="tspan30055"
          x={-1.4318888}
          y={18.691557}
          style={{
            strokeWidth: 2.85691,
          }}
        >
          {props.nbr}
        </tspan>
      </text>
    </g>
  </svg>
);
