import React, { useState, useEffect } from 'react';
import { CTX } from "../components/Store";
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default function LogIns() {
  const { st, sendReq } = React.useContext(CTX);
  const email = React.createRef("");
  const password = React.createRef("");
  const eform = React.createRef(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    //console.log('login useEff',st.register_stat);
    if (st.register_stat === 'Not Found') {
      setMessage('Registration not found');
    } else {
      localStorage.setItem("sgaRegister", st.register_stat);
    }
  }, [st.register_stat],
  );

  const handleChange = (event) => {
    event.preventDefault();
    setMessage('');
  }

  const submitForm = (event) => {
    event.preventDefault();
    //setEditing(false);
    setMessage('');

    const req = `"{"event":"login-req","data":" { 'email': '${email.current.value}', 'pswd': '${password.current.value}'}"}"`;

    sendReq({ msg: req });
    email.current.value = '';
    password.current.value = '';
  }

  const handleCancel = (event) => {
    event.preventDefault();
    this.props.history.push('/');
  }

  return (
   <>
   <h1 style={{textAlign: 'center'}}>Login</h1>
   <div style={{ margin: '5px', fontWeight: 'bold' }}>
     <Form ref={eform} onSubmit={submitForm}>
       <Row className="mb-3">
         <Form.Group as={Col} controlId="formGridEmail">
           <Form.Label>From</Form.Label>
           <Form.Control name='email' type="email" placeholder="Enter email"
	      ref={email} onChange={handleChange} required autocomplete="off"/>
         </Form.Group>
	    
         <Form.Group as={Col} controlId="formGridName">
           <Form.Label>Password</Form.Label>
           <Form.Control name='password' type="password" autocomplete="off"
	     ref={password} placeholder="Password" onChange={handleChange} required />
         </Form.Group>
       </Row>
       <div className='lcontainer'>
         <div>
          <button className='lbutton' type="submit">Submit</button>&nbsp;
	 </div>
	 <div>
          <button className='lbutton' onClick={handleCancel}>Cancel</button>
	 </div>
       </div>
       <div className="fRow ext-center">
          <span className='emsg'>{message}</span>
       </div>

     </Form>
   </div>
   </>
  );
}
