import React from "react";
import { Link, NavLink  } from "react-router-dom";

import './Home.css';
//import img1 from '../img/gypsy3C2.jpg';
import img3 from '../img/woman-star.jpg';
import img6 from '../img/chamber-5264172_640.jpg'
import img8 from '../img/clock-5993732_1280.jpg'
import img14 from '../img/ulysses-4578768_640.jpg'
//import img17 from '../img/sunMoon.jpg'
import img17 from '../img/lphases-min.jpg'
import img18 from '../img/astrologer2.jpg'
import img21 from '../img/BookPic.jpg'
import img22 from '../img/womanFlute.jpg'
import img23 from '../img/occultRoom.jpg'
import img24 from '../img/books-1702790_640.jpg'
import img25 from '../img/light-bulbs-uranus.jpg'
import img26 from '../img/saturn-reaper.jpg'
import img27 from '../img/moon-intelligence.jpg'
//import img28 from '../img/book-reading3.png'
import img29 from '../img/ai-generated-8629249_1280B-min.png'
import img30 from '../img/angel02-min.jpg'
/*
 
       <div className="toverlay">
          <div className="toverlay-text7">
	  <h2 style={{Float: 'left', Width: '67%', TextAlign: 'left'}}></h2>
	      <p style={{Float: 'left', Width: '33%', textAlign: 'right'}}>Readings by Jack Hopkins</p>
          </div>
       </div>


      
	  <div class="jcounter">
	    <div class="jcounter-left">
	      <img class="jcounter.img" src="https://via.placeholder.com/100" alt="Counter">
	    </div>

	    <div class="jcounter-right">
	      <h1>Readings by Jack Hopkins</h1>
	    </div>
	  </div>
*/
export default function Home() {

  let href='https://www.spiritguideastrology.com/';
//  let fb='https://youtu.be/epboqzygRDA';
  let howto='/https://youtu.be/bts0MO49tlI';
  let fbastro = 'https://www.youtube.com/playlist?list=PLzvSAbWoOuUkWbQe4T7c1eTk-y8EpM9Pn';
  
  return (
    <>  
  <div style={{zIndex: 1}} className="hcontainer">

    <div className="gallery-container w-2 h-2">
      <div className="gallery-item">
        <div className="image">
          <img src={img29} alt="astrological journey"/>
        </div>
      </div>
    </div>

    <div style={{zIndex: 1}} className="gallery-container w-1 h-1">
      <div style={{zIndex: 1}} className="gallery-item">
      <a href='/bookreading'>
        <div style={{zIndex: 1}} className="image">
          <img style={{zIndex: 1}} src={img18} alt="people"/>
            <div className="toverlay">
              <div className="toverlay-text4">
	        <h3 className='img-title2'>Book a Reading</h3>
              </div>
           </div>
        </div>
        <div className="text">Reading</div>
      </a>
      </div>
    </div>

    <div className="gallery-container w-1 h-1">
      <div className="gallery-item">
      <NavLink to="/charts">
        <div className="image">
          <img src={img24} alt="charts"/>
            <div className="toverlay">
              <div className="toverlay-text4">
	        <h3 className='img-title2'>Chart Library</h3>
              </div>
           </div>
        </div>
        <div className="text">Charts</div>
        </NavLink>
      </div>
    </div>

    <div className="gallery-container w-1 h-1">
      <div className="gallery-item">
      <NavLink to="https://www.amazon.com/stores/Jack%20Hopkins/author/B09WX8FMRP">
        <div className="image">
          <img src={img21} alt="Books"/>
            <div className="toverlay">
              <div className="toverlay-text4">
	        <h2 className='img-title2'>My Books</h2>
              </div>
           </div>
        </div>
        <div className="text">Books</div>
        </NavLink>
      </div>
    </div>

    <div className="gallery-container  w-1 h-1">
      <div className="gallery-item">
       <a href='/lphases'>
        <div className="image">
          <img src={img17} alt="lunar phases"/>
            <div className="toverlay">
              <div className="toverlay-text4">
	        <h2 className='img-title2'>The 28 Lunar Phases</h2>
              </div>
           </div>
        </div>
        <div className="text">SunMoon</div>
        </a>
      </div>
    </div>
    </div>

  <div className="xgrid-container">
   <div className="xitem1 section-title">
      <h4 style={{fontSize: '1.4rem', padding: '0', margin: '0'}}>&nbsp;&nbsp;Articles</h4>
  </div>
  <div className="xitem2 section-title">
      <h4 style={{fontSize: '1.4rem', padding: '0', margin: '0'}}>&nbsp;&nbsp;Videos</h4>       
   </div>
  <div className="gallery-container w-1 h-1 xitem3">
    <div className="gallery-item">
      <a href='https://youtu.be/bts0MO49tlI'>
        <div className="image">
          <img src={img27} alt="people"/>
            <div className="toverlay">
              <div className="toverlay-text8">
	        <h3 className='img-title'>Website Tutorial</h3>
              </div>
           </div>
        </div>
        <div className="text">Website Tutorial</div>
      </a>
    </div>
  </div>
  <div className="gallery-container w-1 h-1 xitem4">
    <div className="gallery-item">
      <a href='https://youtu.be/bts0MO49tlI'>
        <div className="image">
          <img src={img3} alt="lunar types"/>
            <div className="toverlay">
              <div className="toverlay-text8">
	        <h3 className='img-title'>Lunar Types</h3>
              </div>
           </div>
        </div>
        <div className="text">Lunar Types</div>
      </a>
    </div>
  </div>
  <div className="gallery-container w-1 h-1 xitem5">
    <div className="gallery-item">
      <NavLink to="/ytvideos">
        <div className="image">
          <img src={img14} alt="charts"/>
          <div className="toverlay">
            <div className="toverlay-text8">
              <h2>Videos</h2>
            </div>
          </div>
        </div>
        <div className="text">Videos</div>
      </NavLink>
     </div>
  </div>
  <div className="gallery-container w-1 h-1 xitem6">
    <div className="gallery-item">
      <a href='/sunmoon'>
        <div className="image">
          <img src={img30} alt="Sun & Moon"/>
          <div className="toverlay">
	    <div className="toverlay-text8">
	      <h3 className='img-title'>Sun & Moon</h3>
            </div>
          </div>
        </div>
        <div className="text">Sun & Moon</div>
      </a>
    </div>
  </div>
  <div className="gallery-container w-1 h-1 xitem7">
    <div className="gallery-item">
      <a href='https://youtu.be/N-YbtfBFWPs'>
        <div className="image">
          <img src={img6} alt="quadrants"/>
            <div className="toverlay2">
              <div className="toverlay-text8">
	        <h3 className='img-title'>Quadants</h3>
              </div>
           </div>
        </div>
        <div className="text">Quadants</div>
      </a>
    </div>
  </div> 
</div>
  
<div className="xgrid-container2"> 
  <div className="gallery-container w-1 h-1 xitem8">
    <div className="gallery-item">
      <a href='https://youtu.be/20Rl2pPI6Nw'>
        <div className="image">
          <img src={img26} alt="saturn"/>
            <div className="toverlay2">
              <div className="toverlay-text">
	        <h3 className='img-title'>Saturn</h3>
              </div>
           </div>
        </div>
        <div className="text">Saturn</div>
      </a>
    </div>
  </div> 
  <div className="gallery-container w-1 h-1 xitem9">
    <div className="gallery-item">
      <a href='https://youtu.be/Yd5xcegkguY'>
        <div className="image">
          <img src={img25} alt="uranus"/>
            <div className="toverlay2">
              <div className="toverlay-text">
	        <h3 className='img-title'>Uranus</h3>
              </div>
           </div>
        </div>
        <div className="text">Uranus</div>
      </a>
    </div>
  </div> 
  <div className="gallery-container w-1 h-1 xitem10">
    <div className="gallery-item">
      <a href='https://youtu.be/WixLJukNamw'>
        <div className="image">
          <img src={img23} alt="neptune"/>
            <div className="toverlay2">
              <div className="toverlay-text">
	        <h3 className='img-title'>Neptune</h3>
              </div>
           </div>
        </div>
        <div className="text">Neptune</div>
      </a>
    </div>
  </div>
  <div className="gallery-container w-1 h-1 xitem11">
    <div className="gallery-item">
       <a href='https://youtu.be/ph4HrZqC2Tc'>
        <div className="image">
          <img src={img8} alt="asteroids"/>
            <div className="toverlay2">
              <div className="toverlay-text">
	        <h3 className='img-title'>Asteroids</h3>
              </div>
           </div>
        </div>
        <div className="text">Asteroids</div>
        </a>
    </div>
  </div>
  
</div>

  </>
  );
};

