import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import emailjs from '@emailjs/browser';
import { useNavigate} from "react-router-dom";
import './BookReading.css';

export default function BookReading() {
  const navigate = useNavigate();
  const eform = useRef();


  const sendEmail = (e) => {
     e.preventDefault();
        console.log('eform.current', eform.current);
        emailjs.sendForm('service_9babd02', 'template_9v9qs51', eform.current, 'liN0GXF_SJoUzO1Nx')
          .then((result) => {
           console.log('result: ',result.text);
            navigate('/');
           }, (error) => {
            console.log('error:', error.text);
        });
  };

  return (
    <>
    <h1 style={{textAlign: 'center'}}>Book a Reading  $70</h1>
    <div style={{ margin: '5px', fontWeight: 'bold' }}>
      <Form ref={eform}  onSubmit={sendEmail}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>From</Form.Label>
            <Form.Control name='from_email' type="email" placeholder="Enter email" required />
          </Form.Group>
  
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>Name</Form.Label>
            <Form.Control name='full_name' type="text" placeholder="Full Name" required />
          </Form.Group>
        </Row>
  
        <Row className="mb-3">
        <Form.Group as={Col} controlId="formBirthDate">
          <Form.Label>Birth Date</Form.Label>
          <Form.Control type="text" name='birth_date' placeholder="Birth Date" required />
        </Form.Group>
  
        <Form.Group as={Col} controlId="formBirthTime">
          <Form.Label>Birth Time</Form.Label>
          <Form.Control type="text" name='birth_time' placeholder="Birth Time" required />
        </Form.Group>
        </Row>
	  
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control name='birth_city' placeholder="Birth City" required />
          </Form.Group>
  
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control name='birth_state' placeholder="Birth State" required/>
          </Form.Group>
        </Row>
  
        <Form.Group className="mb-3" controlId="formGridMessage">
           <Form.Label>Questions</Form.Label>
            <Form.Control as='textarea' rows={3} name='message' placeholder="Questions" />
        </Form.Group>

	<div style={{textAlign: 'center'}}>
	  <Button variant="primary" type="submit">Submit</Button>
  	</div>
      </Form>
      </div>
      </>
    );
}
