// for clickable grid, checkout:
// https://codepen.io/gaearon/pen/aWWQOG?editors=1111

import React, { useState, useEffect, useRef } from "react";
import { CTX } from "../components/Store";
import * as d3 from 'd3';
import {Icon} from "./Icon";
import { pAbr, a_names, aspRng01,aspRng02 } from './util';

export default function Aspects() {
  const { st } = React.useContext(CTX);
  const aspcont = useRef(null);

  const a0 = [];
  const a1 = [];
  const side = 30;
  const rows = 15;
  const min = 0;
  const max = 450;

  const [p1] = useState([]);
  const [p2] = useState([]);

  for (let i = 0; i <= rows; i++){
    a0[i] = { x1: i * side, y1: min,
      x2: i * side, y2: max, };
    a1[i] = { x1: min , y1: i * side,
      x2: max, y2: i * side, };
  }

  for (let i = 0; i < 14; i++){
    p1[i] = `translate(0 ${(i * side) + side}) scale(.4)`;
    p2[i] = `translate(${(i*side) + side} 0) scale(.4)`;
  }

// returns an array of predict2natal hit objects
  const getPrgHits = (nlng, plng, asp_rng) => {
    let pHits = [];

    // outer loop n: natal planets
    // inner loop p: predict planets
    let pMax = nlng.length;
    // bypass pred asc/mc for tr & lp
    if (st.ptype === 'tr' || st.ptype === 't2t' || st.ptype === 'lp' ) {
        pMax = nlng.length -2;
    }

    for (let n = 0; n < nlng.length; n++) {
      for (let p = 0; p < pMax; p++) {
        if (p === 11) continue; // Node jjh fix
        let ppl = plng[p];
        if (ppl < nlng[n]) {
          ppl += 360.0;
        }
        let arc = ppl - nlng[n];
        if (arc < 2.0) {
          arc += 360.0;
        }

        for (let a = 0 ; a < asp_rng.length; a++) {
          if (arc >= asp_rng[a][0] &&
            arc < asp_rng[a][2]){
            if (st.ptype === 'p2' &&
              a === 0 && p > 4 && p === n) {
                continue;
            }
            pHits.push( {
              p: p,
              a: a,
              n: n
            });
          }
        }
      }
    }
    return (pHits);
  }

// returns an array of predict2natal hit objects
  const getT2tHits = (nlng, plng, asp_rng) => {
    let pHits = [];

    // outer loop n: natal planets
    // inner loop p: predict planets
    let nMax = nlng.length -2;
    let pMax = nlng.length -2;

    for (let n = 0; n < nMax; n++) {
      for (let p = 0; p < pMax; p++) {
        if (p === 11) continue; // Node jjh fix
        if (p >= n) continue; // Node jjh fix
        let ppl = plng[p];
        if (ppl < nlng[n]) {
          ppl += 360.0;
        }
        let arc = ppl - nlng[n];
        if (arc < 2.0) {
          arc += 360.0;
        }

        for (let a = 0 ; a < asp_rng.length; a++) {
          if (arc >= asp_rng[a][0] &&
            arc < asp_rng[a][2]){
            pHits.push( {
              p: p,
              a: a,
              n: n
            });
          }
        }
      }
    }
    return (pHits);
  }

  // new client
  useEffect(() => {
    const svg = d3.select(aspcont.current);
    svg.selectAll('*').remove();

    if (st.ptype === 'na') {
      for(let i =0; i < st.asps.length; i++ ){
        let pos = `translate(${(st.asps[i][2] * side) + 30} ${(st.asps[i][0] * side) + 30}) scale(1)`;
        let nme = a_names[st.asps[i][1]];
        svg.append("use")
         .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" + nme)
         .attr("transform", pos);
      }

      for (let i = 0; i < st.decl.length - 1; i++) {
          const d1 = Math.abs(st.decl[i]);
          for (let j = i + 1; j < st.decl.length; j++) {
            const d2 = Math.abs(st.decl[j]);
            if (Math.abs(d1 - d2) <= 1.0) {
              let nme = '';
              let pos = `translate(${(i * side) + 30} ${(j * side) + 30}) scale(1)`;
              if ((st.decl[i] >= 0.0 && st.decl[j] <= 1.0) || (st.decl[i] <= 0.0 && st.decl[j] >= 1.0)) {
                nme = 'Counter-Parallel';
              } else {
                nme = 'Parallel';
              }
              svg.append("use")
               .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" + nme)
               .attr("transform", pos);
            }
          }
        }
    }
  }, [st.asps]);

  // prediction aspects
  useEffect(() => {
  if (st.plng && st.plng.length > 0) {
    const svg = d3.select(aspcont.current);
    svg.selectAll('*').remove();

      let aspRng = aspRng01;
      // lp & sa get 1 degree orbs
      if (st.ptype === 'lp' || st.ptype === 'sa' ) {
        aspRng = aspRng02;
      }
      const pHits = (st.ptype === 't2t') ? getT2tHits(st.plng, st.plng, aspRng) : getPrgHits(st.lng, st.plng, aspRng);
      for(let i =0; i < pHits.length; i++ ){
        let pos = `translate(${(pHits[i].n * side) + 30} ${(pHits[i].p * side) + 30}) scale(1)`;
        let nme = a_names[pHits[i].a];
        svg.append("use")
         .attr("xlink:href","/static/media/icons." + st.icons_id + ".svg#" + nme)
         .attr("transform", pos);
      }
    }
}, [st.plng]);

  return (
    <svg 
      viewBox="0 0 490 490"
      xmlns='http://www.w3.org/2000/svg'>
      <g stroke="rgb(10,10,10, .5)">

        {/* grid lines */}
        {a0.map((q, ix) =>
          <line id='a0' key={ix} x1={q.x1} y1={q.y1} x2={q.x2} y2={q.y2} stroke="rgb(10,10,10, .5)" strokeWidth="1" />
        )}

        {a1.map((q, ix) =>
          <line id='a1' key={ix} x1={q.x1} y1={q.y1} x2={q.x2} y2={q.y2} stroke="rgb(10,10,10, .5)" strokeWidth="1" />
        )}

        {/* planet glyphs */}
        {p1.map((rr, ix) =>
          <Icon key={ix} name={pAbr[ix]} transf={p1[ix]}/>
        )}
        {p2.map((rr, ix) =>
          <Icon key={ix} name={pAbr[ix]} transf={p2[ix]}/>
        )}
        <g ref={aspcont}></g>
      </g>
    </svg>
  );
}
