import React, { useState, useEffect } from "react";
import { CTX } from "../components/Store";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Register() {
  const { st, sendReq } = React.useContext(CTX);

  const email = React.createRef(null);
  const password = React.createRef(null);
  const password_confirmation = React.createRef(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    localStorage.setItem("sgaRegister", st.register_stat);
  }, [st.register_stat],
  );

  const handleChange = (event) => {
    event.preventDefault();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password.current.value !==  password_confirmation.current.value) {
      this.setState({ message: 'Passwords do not match' });
    } else {
      setMessage('');
      const req = `"{"event":"register-req","data":" { 'email': '${email.current.value}', 'pswd': '${password.current.value}'}"}"`;
      sendReq({ msg: req });
      email.current.value = '';
      password.current.value = '';
      password_confirmation.current.value = '';
    }
  }

  return (
    <div>
    <h3 className='mhdr'>Register New Client</h3>
    <Form className="newchartform" onSubmit={handleSubmit} >
      <Row>
      <Col>
        <Form.Control type="email" name="email" placeholder="Email" autocomplete="off"
          ref={email} onChange={handleChange} required />
      </Col>
      </Row>
      <Row>
    <Col>
       <Form.Control type="password" name="password" placeholder="Password"
          ref={password} onChange={handleChange} required />
    </Col>
    </Row>
      <Row>
      <Col>
        <Form.Control type="password" name="password_confirmation"
          placeholder="Password Confirmation" ref={password_confirmation}
          onChange={handleChange} required />
      </Col>
      </Row>
      <Row>
      <div className="col text-center">
      <Button variant="primary" type="submit">
      Submit
    </Button>
    </div>
    </Row>
      <Row>
      <div className="col text-center">
      <span className='emsg'>{message}</span>
    </div>
    </Row>
   
    </Form>
    </div>
  );
}
