import React from "react";
import "./TLEdit.css";
import * as FaIcons from "react-icons/fa";
//import * as AiIcons from "react-icons/ai";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleSubmit, }) => {
    return (
      <tr>
        <td>
          <input 
  	    style={{width: "90px", color: "red", fontSize: "16px"}}
            type="text"
            required="required"
            placeholder="Enter a date..."
            name="fullName"
            value={editFormData.fullName}
            onChange={handleEditFormChange}
          ></input>
        </td>
        <td>
          <input 
	    style={{width: "490px", color: "green", fontSize: "16px"}}
	    type="text"
	    required="required"
	    placeholder="Enter an event..."
	    name="address"
	    value={editFormData.address}
	    onChange={handleEditFormChange}
	  ></input>
	</td>
	<td style={{width: "100%", height: "100%"}}>
	  <a href="" onClick={handleSubmit}><FaIcons.FaThumbsUp/></a>&nbsp;
	  <a href="" onClick={handleCancelClick}><FaIcons.FaThumbsDown/></a>
	</td>
      </tr>
    );
};

export default EditableRow;
