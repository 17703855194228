import { sprintf } from "sprintf-js";

export const signs = [
  "aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn", "aquarius", "pisces"
];

export const pAbr = [
  "sun", "mon", "mer", "ven", "mar", "jup","sat",
  "ura", "nep", "plu", "nod", "chi", "asc", "mc", "des", "ic"
];

export const sAbr = [
  "Ars", "Tau", "Gem", "Can", "Leo", "Vir",
  "Lib", "Sco", "Sag", "Cap", "Aqu", "Pis"
];

export const pl_names = [
  'sun', 'moon', 'mercury', 'venus', 'mars',
  'sun', 'moon', 'mercury', 'venus', 'mars',
  'jupiter', 'saturn', 'uranus', 'neptune',
  'pluto', 'node', 'chiron', 'asc', 'mc'
];

export const a_names = [
  'Cnj', 'WxSms', 'WxSxt', 'WxSqr', 'WxTri',
  'WxSsq', 'Opp', 'WnSsq', 'WnTri', 'WnSqr',
  'WnSxt', 'WnSms' ];

export const clrs = [
    '#1b70fc', '#faff16', '#d50527', '#158940',
    '#f898fd', '#24c9d7', '#cb9b64', '#866888',
    '#1afd5c', '#c4f546', '#3d88d8', '#bd3896',
    '#1397a3', '#f940a5', '#66aeff', '#d097e7',
    '#22e67a', '#e509ae', '#9dabfa', '#437e8a',
    '#b21bff', '#ff7b91', '#94aa05', '#ac5906',
    '#82a68d', '#fe6616', '#7a7352', '#f9bc0f',
    '#b65d66', '#07a2e6', '#c091ae', '#8a91a7',
    '#88fc07', '#ea42fe', '#9e8010', '#10b437',
    '#c281fe', '#f92b75', '#07c99d', '#a946aa',
    '#bfd544', '#16977e', '#ff6ac8', '#a88178',
    '#5776a9', '#678007', '#fa9316', '#85c070',
    '#6aa2a9', '#989e5d', '#fe9169', '#cd714a',
    '#6ed014', '#c5639c', '#c23271', '#698ffc',
    '#678275', '#c5a121', '#a978ba', '#ee534e',
    '#d24506', '#59c3fa', '#ca7b0a', '#6f7385',
    '#9a634a', '#48aa6f', '#ad9ad0', '#d7908c',
    '#6a8a53', '#8c46fc', '#8f5ab8', '#fd1105',
    '#7ea7cf', '#d77cd1', '#a9804b', '#0688b4',
    '#6a9f3e', '#ee8fba', '#a67389', '#9e8cfe',
    '#bd443c', '#6d63ff', '#d110d5', '#798cc3',
    '#df5f83', '#b1b853', '#bb59d8', '#1d960c',
    '#867ba8', '#18acc9', '#25b3a7', '#f3db1d',
    '#938c6d', '#936a24', '#a964fb', '#92e460',
    '#a05787', '#9c87a0', '#20c773', '#8b696d',
    '#78762d', '#e154c6', '#40835f', '#d73656',
    '#fe6ef9', '#d86507', '#8b900a', '#d47270',
    '#e8ac48', '#cf7c97', '#cebb11', '#718a90',
    '#e78139', '#ff7463', '#bea1fd'
  ];

  // larger obs on hard asps
export const aspRng01 = [
    [358, 360, 362],
    [44, 45, 46],
    [59, 60, 61],
    [88, 90, 92],
    [119, 120, 121],
    [134, 135, 136],
    [178, 180, 182],
    [224, 225, 226],
    [239, 240, 241],
    [268, 270, 272],
    [299, 300, 301],
    [314, 315, 316]
  ];
  // 1 degree orbs
  export const aspRng02 = [
    [358.9, 360, 361],
    [43.9, 45, 46],
    [58.9, 60, 61],
    [88.9, 90, 91],
    [118.9, 120, 121],
    [133.9, 135, 136],
    [178.9, 180, 181],
    [223.9, 225, 226],
    [238.9, 240, 241],
    [268.9, 270, 271],
    [298.9, 300, 301],
    [313.9, 315, 316]
  ];

  export const LPH48 = [
 0.0, 7.5, 12.857142857142858, 15.0, 22.5, 25.714285714285715, 30.0, 37.5, 38.57142857142857, 45.0, 51.42857142857143, 52.5, 60.0, 64.28571428571429, 67.5, 75.0, 77.14285714285714, 82.5, 90.0, 97.5, 102.85714285714286, 105.0, 112.5, 115.71428571428572, 120.0, 127.5, 128.57142857142858, 135.0, 141.42857142857144, 142.5, 150.0, 154.28571428571428, 157.5, 165.0, 167.14285714285714, 172.5, 180.0, 187.5, 192.85714285714286, 195.0, 202.5, 205.71428571428572, 210.0, 217.5, 218.57142857142858, 225.0, 231.42857142857144, 232.5, 240.0, 244.2857142857143, 247.5, 255.0, 257.14285714285717, 262.5, 270.0, 277.5, 282.8571428571429, 285.0, 292.5, 295.7142857142857, 300.0, 307.5, 308.57142857142856, 315.0, 321.42857142857144, 322.5, 330.0, 334.2857142857143, 337.5, 345.0, 347.14285714285717, 352.5, 360.0];


  export const LPHNME48 = [ 
"P1-1","P1-2","P2-2","P2-3","P2-4","P3-4","P3-5","P3-6","P4-6","P4-7","P5-7",
"P5-8","P5-9","P6-9","P6-10","P6-11","P7-11","P7-12","P8-1","P8-2","P9-2",
"P9-3", "P9-4","P10-4","P10-5","P10-6","P11-6","P11-7","P12-7","P12-8",
"P12-9","P13-9", "P13-10","P13-11","P14-11","P14-12","P15-1","P15-2","P16-2",
"P16-3","P16-4", "P17-4","P17-5","P17-6","P18-6","P18-7","P19-7","P19-8",
"P19-9","P20-9","P20-10", "P20-11","P21-11","P21-12","P22-1","P22-2","P23-2",
"P23-3","P23-4","P24-4","P24-5", "P24-6","P25-6","P25-7","P26-7","P26-8",
"P26-9","P27-9","P27-10","P27-11","P28-11", "P28-12","P1-1"];

  export const ASPRNG28EXT = [
  [359.0, 0.0, 1.0],
  [11.857142857142858, 12.857142857142858, 13.857142857142858],
  [24.714285714285715, 25.714285714285715, 26.714285714285715],
  [35.0, 36.0, 37.0],
  [37.57142857142857,  38.57142857142857,  39.57142857142857],
  [50.42857142857143,  51.42857142857143,  52.42857142857143],
  [63.28571428571429,  64.28571428571429,  65.28571428571429],
  [71.0,  72.0,  73.0],
  [76.14285714285714,  77.14285714285714,  78.14285714285714],
  [89.0, 90.0, 91.0],
  [101.85714285714286,  102.85714285714286,  103.85714285714286],
  [107.0, 108.0, 109.0],
  [114.71428571428572,  115.71428571428572,  116.71428571428572],
  [127.57142857142858,  128.57142857142858,  129.57142857142858],
  [140.42857142857144,  141.42857142857144,  142.42857142857144],
  [143.0, 144.0,  145.0],
  [153.28571428571428,  154.28571428571428,  155.28571428571428],
  [166.14285714285714,  167.14285714285714,  168.14285714285714],
  [179.0, 180.0, 181.0],
  [191.85714285714286,  192.85714285714286,  193.85714285714286],
  [204.71428571428572,  205.71428571428572,  206.71428571428572],
  [215.0, 216.0, 217.0],
  [217.57142857142858,  218.57142857142858,  219.57142857142858],
  [230.42857142857144,  231.42857142857144,  232.42857142857144],
  [243.2857142857143,   244.2857142857143,   245.2857142857143],
  [251.0, 252.0, 253.0],
  [256.14285714285717,  257.14285714285717,  258.14285714285717],
  [269.0, 270.0, 271.0],
  [281.8571428571429,  282.8571428571429,  283.8571428571429],
  [287.0, 288.0, 289.0],
  [294.7142857142857,  295.7142857142857,  296.7142857142857],
  [307.57142857142856,  308.57142857142856,  309.57142857142856],
  [320.42857142857144,  321.42857142857144,  322.42857142857144],
  [323.0, 324.0, 325.0],
  [333.2857142857143,  334.2857142857143,  335.2857142857143],
  [346.14285714285717,  347.14285714285717,  348.14285714285717],
  [359.0, 360.0, 361.0]
  ];
  export const ASPRNG28EXTx = [
  [359.0, 0.0, 1.0],
  [11.857142857142858, 12.857142857142858, 13.857142857142858],
  [24.714285714285715, 25.714285714285715, 26.714285714285715],
  [37.57142857142857,  38.57142857142857,  39.57142857142857],
  [50.42857142857143,  51.42857142857143,  52.42857142857143],
  [63.28571428571429,  64.28571428571429,  65.28571428571429],
  [71.0,  72.0,  73.0],
  [76.14285714285714,  77.14285714285714,  78.14285714285714],
  [89.0, 90.0, 91.0],
  [101.85714285714286,  102.85714285714286,  103.85714285714286],
  [114.71428571428572,  115.71428571428572,  116.71428571428572],
  [127.57142857142858,  128.57142857142858,  129.57142857142858],
  [140.42857142857144,  141.42857142857144,  142.42857142857144],
  [143.0, 144.0,  145.0],
  [153.28571428571428,  154.28571428571428,  155.28571428571428],
  [166.14285714285714,  167.14285714285714,  168.14285714285714],
  [179.0, 180.0, 181.0],
  [191.85714285714286,  192.85714285714286,  193.85714285714286],
  [204.71428571428572,  205.71428571428572,  206.71428571428572],
  [215.0, 216.0, 217.0],
  [217.57142857142858,  218.57142857142858,  219.57142857142858],
  [230.42857142857144,  231.42857142857144,  232.42857142857144],
  [243.2857142857143,   244.2857142857143,   245.2857142857143],
  [256.14285714285717,  257.14285714285717,  258.14285714285717],
  [269.0, 270.0, 271.0],
  [281.8571428571429,  282.8571428571429,  283.8571428571429],
  [287.0, 288.0, 289.0],
  [294.7142857142857,  295.7142857142857,  296.7142857142857],
  [307.57142857142856,  308.57142857142856,  309.57142857142856],
  [320.42857142857144,  321.42857142857144,  322.42857142857144],
  [333.2857142857143,  334.2857142857143,  335.2857142857143],
  [346.14285714285717,  347.14285714285717,  348.14285714285717],
  [359.0, 360.0, 361.0]
];

export const ASPNME28EXTx =  [
  "P1", "P2", "P3", "P4", "P5", "P6", "1/5", "P7", "P8", "P9", "P10", "P11", "P12", "2/5", "P13", "P14", "P15", "P16", "P17", "3/5", "P18", "P19", "P20", "P21", "P22", "P23", "4/5", "P24", "P25", "P26", "P27", "P28"
];
export const ASPNME28EXT =  [
  "P1", "P2", "P3", "1/10", "P4", "P5", "P6", "1/5", "P7", "P8", "P9", "3/10","P10", "P11", "P12", "2/5", "P13", "P14", "P15", "P16", "P17", "3/5", "P18", "P19","7/10", "P20", "P21", "P22", "P23", "4/5", "P24", "P25", "P26", "9/10","P27", "P28"
];
  
export const modalSmall = {
  content : {
     top : '50%',
     left : '50%',
     width : '100%',
     right : 'auto',
     bottom : 'auto',
     transform : 'translate(-50%, -50%)',
     backgroundColor: '#ededed',
   }
};
export const modalBig = {
  content : {
     top : '50%',
     left : '50%',
     width : '60%',
     right : 'auto',
     bottom : 'auto',
     transform : 'translate(-50%, -50%)',
     backgroundColor: '#ededed',
   }
};

export function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

export const valDt = (bdt) => {
  let dt = new Date(bdt);
  if (Object.prototype.toString.call(dt) === "[object Date]") {
    if (isNaN(dt.getTime())) {
      return '';
    }
  } else {
    return '';
  }
  return(`${dt.getMonth()+1}/${dt.getDate()}/${dt.getFullYear()}`);
}

export const valDt2 = (bdt) => {
  let dt = new Date(bdt);
  if (Object.prototype.toString.call(dt) === "[object Date]") {
    if (isNaN(dt.getTime())) {
      return '';
    }
  } else {
    return '';
  }
  return(`${dt.getFullYear()}-${dt.getMonth()+1}-${dt.getDate()}`);
}

export function yyyymmdd(dt ){
  let dd = dt.getDate();
  let mm = dt.getMonth()+1;
  let yyyy = dt.getFullYear();
  if(dd<10) {
    dd='0'+dd;
  }
  if(mm<10) {
    mm='0'+mm;
  }
  return [yyyy, mm, dd];
}

export function mod(n, m) {
  return ((m % n) + n) % n;
};

export function deg2Sgn2(d) {
  d = mod(360, d);
  let deg = Math.floor(d);
  let min = Math.round((d - deg) * 60);
  let sgn = sAbr[Math.floor(deg / 30)];
  deg = Math.floor(d % 30);
  return sprintf("%02d%s%02d", deg, sgn, min);
};

export function deg2Sgn(d) {
  d = mod(360, d);
  const deg = Math.floor(d);
  const min = Math.round((d - deg) * 60);
  const sgn = deg / 30;
  return [Math.floor(sgn), deg, min];
};

export function fmtDays(days){
  return sprintf('%.2f Days', days);
}

export function getLunarPhase(ptype, sun, moon){
  let pMoon = moon;
  if (pMoon < sun) {
    pMoon +=360.0;
  }
  let sm_arc = pMoon - sun ;
  let sm_arc48 = pMoon - sun ;
  let H28 = 360.0 / 28.0;
  let H48 = 360.0 / 48.0;
  if ((sm_arc % H28) > (H28 - 1.0) ){
     sm_arc += 1.0;
  }
  if ((sm_arc48 % H48) > (H48 - 0.5) ){
     sm_arc48 += 0.5;
  }

  if (ptype === "lp") {
    return '';
  }
  let idx = lun_phase48(sm_arc48);
  return LPHNME48[idx];
}
/*
function lun_phase28(tarc) {
  let ret = 0;

  //for r in 0..= 28 {
  for(let r=0; r < ASPRNG28EXT.length; r++) {
    if (r === 0 && (tarc > ASPRNG28EXT[r][0] ||
      tarc < ASPRNG28EXT[1][1])) {
      ret = 0;
      break;
    } else if (r !== 0 &&
        (tarc >= ASPRNG28EXT[r - 1][1] &&
          tarc < ASPRNG28EXT[r][1])) {
          ret = r - 1;
          break;
        }
  }
  return ret;
}
i*/
function lun_phase48(tarc) {
  let ret = 0;

  for(let r=0; r < LPH48.length; r++) {
     if (tarc >= LPH48[r] && tarc < LPH48[r+1]) {
        ret = r;
        break;
     }
  }
  return ret;
}

//function sortFloat(a,b) { return a - b; }

export function revjul(jd) {
  const z = Math.floor(jd + 0.5);
  const f = (jd + 0.5) - z;
  let a, b, c, d, e;

  if (z < 2299161) {
      a = z;
  } else {
      const alpha = Math.floor((z - 1867216.25) / 36524.25);
      a = z + 1 + alpha - Math.floor(alpha / 4);
  }

  b = a + 1524;
  c = Math.floor((b - 122.1) / 365.25);
  d = Math.floor(365.25 * c);
  e = Math.floor((b - d) / 30.6001);

  const day = b - d - Math.floor(30.6001 * e) + f;
  const month = e < 14 ? e - 1 : e - 13;
  const year = month > 2 ? c - 4716 : c - 4715;

  return month + "/" + day + "/" + year;
}
