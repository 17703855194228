import React from "react";
import {useNavigate} from "react-router-dom";
var W3CWebSocket = require('websocket').w3cwebsocket;

let timeout;
let socket = null;
const TIMEOUT = 1800000;
// const TIMEOUT = 30000;

const initialState = {
  sbopen: false,
  ptype: '',
  asps: [],
  decl: [],
  hses: [],
  tlines: [],
  tl_sel: '',
  lng: [],
  lltzone: {},
  rtro: [],
  id: '',
  name: '',
  days: 0,
  from: '',
  interps: [],
  icons_id: '',
  login_stat: 'Active',
  lookups: [],
  pevts: [],
  pidx: 0,
  plng: [],
  prtro: [],
  register_stat: 'sga@gmail.com',
  timeline: [],
  to: ''
};

export const CTX = React.createContext(initialState);

const reducer = (state = initialState, action) => {
  const pload = action.pload;

  
//console.log('reducer type/pload',  action.type, pload  );
 // console.log('reducer state',  state  );
  switch (action.type) {
    case "RECEIVE_RESP":
      return { ...state, pload };
    case "TOGGLE_SB":
      return { ...state, sbopen: true };
 case "SUNMOON_RES":
      return { ...state, n_moon_phase: pload.n_moon_phase, n_moon_interp: pload.n_moon_interp, n_moon_summ: pload.n_moon_summ, p_moon_phase: pload.p_moon_phase, p_moon_interp: pload.p_moon_interp, p_moon_summ: pload.p_moon_summ, n_sun_interp: pload.n_sun_interp, n_sun_summ: pload.n_sun_summ, n_sun_title: pload.n_sun_title, p_sun_interp: pload.p_sun_interp, p_sun_summ: pload.p_sun_summ, p_sun_title: pload.p_sun_title }; 
    case "MPINIT":
      return { ...state, n_moon_phase: "", n_moon_interps: "", n_moon_summ: "", p_moon_phase: "", p_moon_interps: "", p_moon_summ: "", n_sun_interps: "", n_sun_summ: "", n_sun_title: "", p_sun_interps: "", p_sun_summ: "", p_sun_title: "" }; 
    case "EXPINIT":
      return { ...state, exp:[] };
    case "TIMEOUT":
      return { ...state, ptype: '', asps:[], decl: [], hses: [], tlines: [], tl_sel: '', id:'', lng:[], name:[], rtro:[], pidx:0, plng:[], prtro:[], pevts:[], timeline:[], mphase: '', mphase8: '', mpinterps: '' };
    case "CHART_RES":
      return { ...state, ptype: 'na', asps: pload.asps, decl: pload.decl, hses: pload.hses, tlines: pload.tlines, tl_sel: '', id:pload.id, lng:pload.lng, name:pload.name, rtro: pload.rtro, pidx:0, plng:[], prtro:[], pevts:[], timeline:[], exp:[] };
    case "EXISTING_CHART_RES":
      return { ...state, id:pload.id, longitude:pload.longitude, latitude:pload.latitude, first_name:pload.first_name, last_name: pload.last_name, dttm: pload.dttm, timezone: pload.timezone, city: pload.city, state: pload.state, author: pload.author, sex: pload.sex, jcal: pload.jcal, lmt: pload.lmt};
    case "IPEVTS_RES":
      return { ...state, days: pload.days, from: pload.from, pevts: pload.ipevts, plng: pload.lng, pidx: pload.pidx, ptype: pload.ptype, prtro: pload.retro, decl: pload.decl, to: pload.to};
    case "PLNG_RES":
      return { ...state, plng: pload.plng, pidx: pload.pidx, ptype: pload.ptype, prtro: pload.retro, decl: pload.decl};
    case "LLTZONE_RES":
      return { ...state, lltzone: pload.lltz};
    case "TLINE_RES":
      return { ...state, timeline: pload.tl, tabIndex: 2 };
    case "INTERPS_RES":
      return { ...state, interps: pload.interps, tabIndex: 1 };
    case "FINTERPS_RES":
        return { ...state, interps: pload.finterps };
    case "P3PLANET_RES":
      return {
        ...state, pevts: pload.ipevts, plng: pload.lng, prtro: pload.retro, ptype: pload.ptype, from: pload.from, to: pload.to, days: pload.days, pidx: pload.pidx  }
    case 'P3INTERPS_RES':
        return {
        ...state, interps: pload.interps,tabIndex: 1
        }
    case 'P3ALL_RES':
        return {
          ...state, pevts: pload.ipevts, plng: pload.lng, prtro: pload.retro, ptype: pload.ptype, from: pload.from, to: pload.to, days: pload.days, pidx: pload.pidx  }
    case "LOOKUP_RES":
      return { ...state, lookups: pload};
    case "EXPLKUP_RES":
      return { ...state, lookups: pload};
    case "EXPEVTS_RES":
      return { ...state, exp: pload.exp};
    case "REG_RES":
      return { ...state, register_stat: pload.register };
    case "LOGIN_RES":
      return { ...state, register_stat: pload.login };
    case "TLUPDT_RES":
      return { ...state, days: pload.days, from: pload.from, pevts: pload.ipevts, plng: pload.lng, pidx: pload.pidx, ptype: pload.ptype, prtro: pload.retro, decl: pload.decl, to: pload.to};
    case "PERADD_RES":
      return { ...state, tlines: pload.pnames };
    default: {
      return state;
    }
  }
}

 timeout = setTimeout(function(){
  if (socket !== null && socket.readyState === 1) {
     console.log("SENDING quit", TIMEOUT);
     socket.send('quit');
  }
  }, TIMEOUT);


//  console.log('check_idle outside', TIMEOUT, timeout);
const check_idle = () => {
//  console.log('check_idle', TIMEOUT, timeout);
  if (timeout){
    clearTimeout(timeout);
  }
}

export default function Store(props) {
  const navigate = useNavigate();
  const [st, dispatch] = React.useReducer(reducer, initialState);

  function sendReq(value) {
    if (value.msg === 'quit') {
      return;
    } else { 
    if (value.msg === 'mpinit') {
      dispatch({ type: "MPINIT", msg: true})
    } else { 
    if (value.msg === 'expinit') {
      dispatch({ type: "EXPINIT", msg: true})
    } else {
       if (!socket || socket.readyState === 3) {
           // dev socket = new W3CWebSocket("wss://127.0.0.1:5000");
           socket = new W3CWebSocket('wss://www.spiritguideastrology.com:6001/');
	   //console.log("SOCKET", socket);
	   //console.log("val", value.msg);
           socket.onerror = (e) => {
              console.log("SOCKET could not connect",e);
           }
           socket.onopen = () => {
	     //console.log("ONOPEN", value.msg);
             socket.send(value.msg);
             check_idle();
           };
           socket.onclose = (evt) => {
	    //console.log("ONCLOSE", evt, evt.reason);
           };
           socket.onmessage = (message) => {
             let jdata = JSON.parse(message.data);
             //console.log("onmessage JDATA", jdata.event);
             if (jdata.event === 'chart-res'){
               dispatch({ type: 'CHART_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'existing-chart-res'){
               dispatch({ type: 'EXISTING_CHART_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'sunmoon-res'){
               dispatch({ type: 'SUNMOON_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'ipevts-res'){
               dispatch({ type: 'IPEVTS_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'lookup-res'){
               dispatch({ type: 'LOOKUP_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'explookup-res'){
               dispatch({ type: 'EXPLKUP_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'expevts-res'){
               dispatch({ type: 'EXPEVTS_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'plng-res'){
               dispatch({ type: 'PLNG_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'tline-res'){
               dispatch({ type: 'TLINE_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'interps-res'){
               dispatch({ type: 'INTERPS_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'finterps-res'){
               dispatch({ type: 'FINTERPS_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'lltzone-res'){
               dispatch({ type: 'LLTZONE_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'p3-planet-res'){
               dispatch({ type: 'P3PLANET_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'p3-interps-res'){
               dispatch({ type: 'P3INTERPS_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'p3-all-res'){
               dispatch({ type: 'P3ALL_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'register-res'){
               dispatch({ type: 'REG_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'login-res'){
               dispatch({ type: 'LOGIN_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'tlupdt-res'){
               dispatch({ type: 'TLUPDT_RES', pload: JSON.parse(jdata.data) });
             }
             if (jdata.event === 'peradd-res'){
               dispatch({ type: 'PERADD_RES', pload: JSON.parse(jdata.data) });
             }
           }
           socket.onclose = () => {
             socket = null;
             //console.log("Connection is closed...");
             dispatch({ type: "TIMEOUT", msg: true})
             navigate('/');
           };
       } else {
         socket.send(value.msg);
         check_idle()
      }
    }
    }
    }
  }

  return (
    <CTX.Provider value={{ st, sendReq }}>
      {props.children}
    </CTX.Provider>
  );
}
